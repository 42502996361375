import { userStore } from "@/store/user"
import AgencyCenterIcon from '@/static/images/icons/tabBar/agency.svg';
import AgencyCenterActiveIcon from '@/static/images/icons/tabBar/agency_active.svg';

import TaskTodoIcon from '@/static/images/icons/tabBar/task_todo.svg';
import TaskTodoActiveIcon from '@/static/images/icons/tabBar/task_todo_active.svg';

import ApproveIcon from '@/static/images/icons/tabBar/approval.svg';
import ApproveActiveIcon from '@/static/images/icons/tabBar/approval_active.svg';

import ListIcon from '@/static/images/icons/tabBar/liebiao2.svg';
import ListActiveIcon from '@/static/images/icons/tabBar/liebiao1.svg';

import ApplicationIcon from '@/static/images/icons/tabBar/application.svg';
import ApplicationActiveIcon from '@/static/images/icons/tabBar/application_active.svg';

import WorkIcon from '@/static/images/icons/tabBar/workbench.svg';
import WorkActiveIcon from '@/static/images/icons/tabBar/workbench_active.svg';

import MineIcon from '@/static/images/icons/tabBar/mine.svg';
import MineActiveIcon from '@/static/images/icons/tabBar/mine_active.svg';

import MessageIcon from '@/static/images/icons/tabBar/message.svg';
import MessageActiveIcon from '@/static/images/icons/tabBar/message_active.svg';

import MoreIcon from '@/static/images/icons/tabBar/more.svg';
import MoreActiveIcon from '@/static/images/icons/tabBar/more_active.svg';


const tabBarConfigs = [
  {
    type: 11,
    pagePath: '/views/cusTabbar/index/task-todo/index',
    text: '业务流程',
    iconPath: TaskTodoIcon,
    selectedIconPath: TaskTodoActiveIcon
  },
  {
    type: 12,
    pagePath: '/views/cusTabbar/index/task-todo/index',
    text: '阶段任务',
    iconPath: TaskTodoIcon,
    selectedIconPath: TaskTodoActiveIcon
  },
  {
    type: 10,
    pagePath: '/views/cusTabbar/index/agency-center/index',
    text: '代办中心',
    iconPath: AgencyCenterIcon,
    selectedIconPath: AgencyCenterActiveIcon
  },
  {
    type: 9,
    pagePath: '/views/cusTabbar/index/task-todo/index',
    text: '任务待办',
    iconPath: TaskTodoIcon,
    selectedIconPath: TaskTodoActiveIcon
  },
  {
    type: 7,
    pagePath: '/views/cusTabbar/index/approve/index',
    text: '审批中心',
    iconPath: ApproveIcon,
    selectedIconPath: ApproveActiveIcon
  },
  {
    type: 1,
    pagePath: '/views/cusTabbar/index/object/list/index',
    text: '列表页',
    iconPath: ListIcon,
    selectedIconPath: ListActiveIcon
  },
  {
    type: 3,
    pagePath: '/views/cusTabbar/index/application/index',
    text: '应用',
    iconPath: ApplicationIcon,
    selectedIconPath: ApplicationActiveIcon
  },
  {
    type: 6,
    pagePath: '/views/cusTabbar/index/workbench/index',
    text: '工作台',
    iconPath: WorkIcon,
    selectedIconPath: WorkActiveIcon
  }, {
    type: 5,
    pagePath: '/views/cusTabbar/index/mine/index',
    text: '我的',
    iconPath: MineIcon,
    selectedIconPath: MineActiveIcon
  }, {
    type: 4,
    pagePath: '/views/cusTabbar/index/message/index',
    text: '消息',
    iconPath: MessageIcon,
    selectedIconPath: MessageActiveIcon
  }, {
    type: 999,
    pagePath: '/views/cusTabbar/index/more/index',
    text: '更多',
    iconPath: MoreIcon,
    selectedIconPath: MoreActiveIcon
  }
]

export const setPagePathWithList = (list: any) => {
  for (const key in list) {
    if (Object.prototype.hasOwnProperty.call(list, key)) {
      const element = list[key] as any;
      const item = tabBarConfigs.find((item) => item.type == element.type)
      if (item) {
        if (element.type == 1) {
          element.pagePath = item.pagePath + '?' + 'objectId=' + element?.tabVO?.objectId  + '&tabId=' + element?.tabVO?.id
        } else {
          element.pagePath = item.pagePath
        }
        element.iconPath = item.iconPath
        element.selectedIconPath = item.selectedIconPath
      }
    }
  }
}

export const getDefaultItem = () => {
  const userInfo = userStore();
  if (userInfo.tabBarList?.length) {
    for (const key in userInfo.tabBarList) {
      if (Object.prototype.hasOwnProperty.call(userInfo.tabBarList, key)) {
        const element = userInfo.tabBarList[key] as any;
        if (element.isDefault) {
          return element
        }
      }
    }
    return userInfo.tabBarList[0]
  } else {
    console.error('tabBarList为空');
  }
}
