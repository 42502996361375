import { defineStore } from "pinia";

export const useBusinessFlowTodoList = defineStore(
  'businessList',
  {
    state: () => {
      return {
        businessListState: {
          scrollTop: 0,
          queryConditon: null as any,
          pageNo: 1,
          refreshing: false,
          finished: false,
          loading: false,
          dataList: [] as any,
          showSkeleton: false,
          defaultObjectName: '全部',
          defaultSelectType: 1,
          totalsTab: [] as any,
          totalListNumber: 0,
          objectId: '',
          izInvoke: false,
          selectObjectId: '-1',
          isTabRefresh: false,
          isRefresh: false,
          objectFieldsList: [] as any,
          showConditionList: [] as any, // 缓存普通筛选的条件
          doneSubmitParams: {
            processType: '7',
            conditionDTOList: [],
            pageNo: 1,
            pageSiz: 10,
            searchValue: '',
            source: 2
          },
          processListParams: {
            processType: '7',
            conditionDTOList: [],
            objectId: "-1",
            pageNo: 1,
            pageSiz: 10,
            searchValue: '',
            showFieldList: [],
            source: 2
          }
        }
      }
    },
    actions: {
      clearBusinessListState() {
        // businessListState.defaultObjectName = ''
        this.businessListState.objectId = ''
        this.businessListState.selectObjectId = '-1'
        this.businessListState.isRefresh = false;
        this.businessListState.isTabRefresh = false;
        this.businessListState.objectFieldsList = []
        // businessListState.showConditionList = [] as any // 缓存普通筛选的条件
        this.businessListState.defaultSelectType = 1
        this.businessListState.totalsTab = [] as any
        this.businessListState.totalListNumber = 0
        this.businessListState.dataList = [] as any
        this.businessListState.izInvoke = false
      }
    }
  }
);