import { getAction } from "../api/http/index";
import type { ITaskTodoParams, IAgencyCenterParams } from 'src/typings/interface';


// 查询任务代办配置
export function getTaskToConfigByTaskCenterId(data: ITaskTodoParams) {
  return getAction(`gbs/taskcenter/queryTaskCenterDatas`, data)
}

// 统一代办配置
export function queryTaskCenterConfig(data: IAgencyCenterParams) {
  return getAction(`gbs/taskcenter/queryTaskCenterConfig`, data)
}

