import { defineStore } from 'pinia';

export const versionStore = defineStore('versionInfo', {
  state: () => {
    return {
      versionInfo: {
        osVersion: '',
        appVersionName: '',
        appVersionCode: '',
        h5VersionName: '',
        h5VersionCode: '',
        deviceType: '',
      } as any,
    };
  },
  actions: {
    clearExtraInfo(){
      this.versionInfo.osVersion=''
      this.versionInfo.appVersionName=''
      this.versionInfo.appVersionCode=''
      this.versionInfo.h5VersionName=''
      this.versionInfo.h5VersionCode=''
      this.versionInfo.deviceType=''
    }
  },
  persist: true
});