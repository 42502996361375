import { userStore } from "../../store/user";
import { useOffLine,useOfflineData } from '@/store/useOffLine'
import request from "./request";
function getToken(url: string){
  const userInfo = userStore();
  const token = userInfo.token
   url = url + '?t_id=' + token
  return url
}


function appendPrefix(url: string) {
  return encodeURI(url);
}

export function getAction(url:string, data?:any, config?:any) {
  const { offlineState } = useOffLine()();
  const { offline2Port } = useOfflineData()();
  if(offlineState.status || offlineState.izPendingPage){
    return offline2Port(url,data);
  }

  url = appendPrefix(url);
  if (data && config) {
    config.params = {
      ...config.params,
      ...data
    }
  } else if (data) {
    config = {
      params: data
    }
  }
  return request.get(url, config);
}


export function postAction(url:any, data?:any, config?:any) {
  const { offlineState } = useOffLine()();
  const { offline2Port } = useOfflineData()();
  if(offlineState.status || offlineState.izPendingPage){
    return offline2Port(url,data);
  }
  url = appendPrefix(url);
  return request.post(url, data, config);
}


export function putAction(url:any, data:any, config?:any) {
  url = appendPrefix(url);
  return request.put(url, data, config);

}


export function deleteAction(url:any, data:any, config?:any) {
  url = appendPrefix(url);
  if (data && config) {
    config.params = {
      ...config.params,
      ...data
    }
  } else if (data) {
    config = {
      params: data
    }
  }
  return request.delete(url, config);
}

/**
 * 为了实现Post下载文件或者打印需求
 * @param url   请求地址
 * @param data  Post form body
 */
export function formPost(url:any, data:any) {
  url = appendPrefix(url);
  formSubmit(url, data, "post");
}

/**
 * 为了实现Get下载文件或者打印需求
 * @param url     请求地址
 * @param params  请求参数
 */
export function formGet(url: any, params: any) {

  url = appendPrefix(url);
  formSubmit(url, params, "get");
}

function formSubmit(url:any, data:any, method:any) {
  const userInfo = userStore();
  const token = userInfo.token
  data = data || {};
  if(method === 'get'){
    if(!data.hasOwnProperty("t_id")){
       data.t_id = token
    }
  }else{
    url = getToken(url)
  }
  let postForm = document.createElement("form");//表单对象
  postForm.method = method;
  postForm.action = process.env.VUE_APP_API_BASE_URL + url.replace(/^\//, '');
  postForm.target = "_blank";

  let keys = Object.keys(data);
  for (let k of keys) {
    let input = document.createElement("input");
    input.setAttribute("name", k);
    input.setAttribute("value", data[k]);
    postForm.appendChild(input);
  }
  document.body.appendChild(postForm);
  postForm.submit();
  document.body.removeChild(postForm);
}

/**
 * 获取文件服务访问路径
 * @param url
 * @param subStr
 * @returns {*}
 */
export function getFileAccessHttpUrl(url) {
  try {
    if (url && (url.startsWith('http') || url.startsWith('https'))) {
      return url;
    } else {
      if (url && url.length > 0) {
        return process.env.VUE_APP_API_BASE_URL + url.replace(/^\//, '');
      }
    }
  } catch (err) {
    return '';
  }
}


/**
 * 将json数据转为form表单数据
 * @param json
 */
export function makeFormData(json) {
  let formData = new FormData();

  for (let key in json){
    formData.append(key, json[key])
  }
  return formData;
}

export function UrlSearchParams(json) {
  return new URLSearchParams(json);
}

/**
 * 将json数据转为url拼接形式
 * @param json
 */
export function makeUrl(json) {
  let search = '';
  if(json instanceof Object) {
    search = Object.keys(json).map(function (key) {
      return key + "=" + json[key];
    }).join("&");
  }
  return search;

}