import { defineStore } from 'pinia';
import { userStore } from '@/store/user';

import AbountAppXiangShu from '@/static/images/icons/mine/about-icon.svg';
import AbountAppHaDian from '@/static/images/icons/mine/hadian-logo-name.png';

export const appBridge = defineStore('appBridge', {
  state: () => {
    return {
      target: 'FSMSaas',
      nativeAppCode:0,
      nativeAppVersion: '',
    };
  },
  actions: {
    setTarget() {
      const VITE_NODE_ENV = import.meta.env.VITE_NODE_ENV;
      if (VITE_NODE_ENV == "hadianProd" || 
	        VITE_NODE_ENV == "hadianTest") {
            this.target = 'HaDianService'
        }
    },
    setNativeAppInfo(_nativeAppCode: number,_nativeAppVersion: string) {
      this.nativeAppCode = _nativeAppCode
      if ((_nativeAppVersion ?? '').length > 0) {
        this.nativeAppVersion = _nativeAppVersion
      }
    },
    getAppName() {
      if (this.target == 'HaDianService') {
        return '哈电服务'
      }
      return '橡数云';
    },
    getLoginPageWelcome() {
      if (this.target == 'HaDianService') {
        return '哈电服务'
      }
      return '橡数科技';
    },
    aboutAppLogo() {
      if (this.target == 'HaDianService') {
        return AbountAppHaDian
      }
      return AbountAppXiangShu
    },
    jPushAlias() {
      let prefix = ''
        const VITE_NODE_ENV = import.meta.env.VITE_NODE_ENV;
        if (VITE_NODE_ENV == 'alpha' ||
            VITE_NODE_ENV == 'gamma' ||
            VITE_NODE_ENV == 'mirror'
        ) {
            prefix = VITE_NODE_ENV
        } else if (VITE_NODE_ENV == 'beta') {
            prefix = 'test'
        } else if (VITE_NODE_ENV == 'production') {
            prefix = 'prod'
        } else if (VITE_NODE_ENV == 'hadianTest') {
            prefix = 'test'
        } else if (VITE_NODE_ENV == 'hadianProd') {
            prefix = 'prod'
        }
        const userInfo = userStore();
        const userId = userInfo?.userInfos?.userInfo?.userId ?? ''
        const tenantCode = userInfo?.tenantCode ?? ''
        if (prefix.length > 0 && tenantCode?.length > 0 && userId.length > 0) {
            const alias = prefix + '_' + tenantCode + '_' + userId
            return alias
        }
        return ''
    },
  },
  persist: true
});