<script setup lang="ts">
import { userStore } from './store/user';
import { locationStore } from "@/store/location"
import '@/style/base.scss';
import { onMounted } from 'vue';
import CustomButtonAction from "@/components/button/CustomButtonAction.vue";
import router from './router';
import { push } from "@/utils/router.ts";
import { showFailToast } from "vant";
import { getNavigationBarHeight, getStatusBarHeight, sendUserInfoToNative } from "@/utils/nativeUtils.ts";
import { appBridge } from '@/store/appBridge';
import { getVersionInfo } from "@/utils/nativeUtils.ts";
import { versionStore } from "@/store/versionInfo"
import { useOfflineData, useOffLine } from '@/store/useOffLine'
const { initDb } = useOfflineData()();
const { offlineState } = useOffLine()();
import emitter from '@/utils/eventBus';
import {dataTransmission} from "@/store/dataTransmission.ts";

const userInfo = userStore();
const token = userInfo.token
// const route = useRoute();
// const {platform} = route.query;
// userInfo.userInfos.platform = platform;
// console.log("平台：",route)
onMounted(() => {
  const { setTarget } = appBridge()
  setTarget()
  // if (offlineState.izInit) {
  //   initDb();
  //   offlineState.izInit = false;
  // }
  setSafeArea();
  // if (offlineState.status) {
  //   router.replace({
  //     path: '/views/mine/offline-setting'
  //   })
  // }
  if (!token) {
    router.replace({
      path: '/login'
    })
  } else {
    // router.push({
    //   path: '/views/cusTabbar/index'
    // })
  }

  type VersionInfo = {
    osVersion: string,
    appVersionName: string,
    appVersionCode: string,
    h5VersionName: string,
    h5VersionCode: string,
    deviceType: string
  }
  // 调用原生的方法，更新version数据并缓存起来
  getVersionInfo((version: VersionInfo) => {
    const { versionInfo } = versionStore();
    versionInfo.osVersion = version?.osVersion ?? ''
    versionInfo.appVersionName = version?.appVersionName ?? ''
    versionInfo.appVersionCode = version?.appVersionCode ?? ''
    versionInfo.h5VersionName = version?.h5VersionName ?? ''
    versionInfo.h5VersionCode = version?.h5VersionCode ?? ''
    versionInfo.deviceType = version?.deviceType ?? ''
  });

  // 发送用户信息&环境给原生端
  sendUserInfoToAppNative()

})

const nativeLocationSuccess = (id: string, result: string) => {
  // 存储值
  const locationInfo = locationStore();
  locationInfo.locationTime = Date.now() + ''
  locationInfo.locationId = id
  locationInfo.locationData = result
}

window.nativeLocationSuccess = nativeLocationSuccess

const sendUserInfoToAppNative = () => {
  //给原生发送一条消息过去
  const __data: any = { 'userId': userInfo?.userInfos?.userInfo?.userId ?? '', 'token': userInfo?.token, 'tenantId': userInfo?.userInfos?.tenantInfo?.id ?? '' }
  const { jPushAlias } = appBridge()
  const _jPushAlias = jPushAlias()
  if (_jPushAlias?.length > 0) {
    __data.jPushAlias = _jPushAlias
  }
  __data.netEnv = import.meta.env.VITE_NODE_ENV
  __data.apiBaseUrl = import.meta.env.VITE_UNIAPP_API_BASE_URL
  __data.h5BaseUrl = import.meta.env.VITE_UNIAPP_H5_BASE_URL
  sendUserInfoToNative(JSON.stringify(__data));
}


const setNavigationBarHeight = (height: number) => {
  const heightPx = height / window.devicePixelRatio;
  userInfo.navigationBarHeight = heightPx;
  document.documentElement.style.setProperty('--xs-safe-area-bottom', `${heightPx}px`);
}

window.setNavigationBarHeight = setNavigationBarHeight;

const setSafeArea = () => {
  getStatusBarHeight((height: number) => {
    userInfo.statusBarHeight = height;
    document.documentElement.style.setProperty('--xs-safe-area-top', `${height}px`);
  })
  getNavigationBarHeight((height: number) => {
    userInfo.navigationBarHeight = height;
    document.documentElement.style.setProperty('--xs-safe-area-bottom', `${height}px`);
  })
}
window.onKeyboardChange = (data: any) => {
  const dataInfo = dataTransmission();
  if (data?.isShow === false) {
    const activeElement = document.activeElement;
    if (activeElement && (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA')) {
      activeElement.blur();
    }
  } else {
    dataInfo.keyboardData = data;
  }
}


</script>

<template>
  <router-view v-slot="{ Component }">
    <KeepAlive>
      <component :is="Component" :key="router.currentRoute.value.name"
        v-if="router.currentRoute.value.meta.keepAlive && !offlineState.status && !offlineState.izPendingPage" />
    </KeepAlive>
  </router-view>
  <router-view v-slot="{ Component }">
    <component :is="Component" :key="router.currentRoute.value.name + new Date().getTime()"
      v-if="!router.currentRoute.value.meta?.keepAlive || (offlineState.status || offlineState.izPendingPage)" />
  </router-view>
</template>

<style lang="scss" scoped></style>
