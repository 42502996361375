// 待办中心
import { queryTaskCenterConfig } from "@/api/taskTodo";
import { defineStore } from "pinia";

export const useAgencyCenter = defineStore('agencyCenter', {
  state: () => {
    return {
      isRefresh: false,
      izInvoke: false,
      showSkeleton: false,
      changeTaskTodoList: false,
      items: [] as any,
      index: 0,
      item: {} as any,
      requestFinish: false,
      isApplication: false,
      listTitles: [] as any,
    }
  },
  actions: {
    async request() {
      if(!this.izInvoke) {
        this.showSkeleton = true;
        this.izInvoke = true;
      }
      const params = {
        clientType: '2'
      }
      const res: any = await queryTaskCenterConfig(params)
      if (res.success) {
        const listTitles = [] as any;
        res.data?.map((item: any, index: number) => {
          let itemName = '';
          if (item.total) {
            itemName = item.name + ' ' + item.total;
          } else {
            itemName = item.name;
          }
          listTitles.push({ value: index, name: itemName })
        })
        this.items = res.data || []
        if (!this.item?.type) {
          this.item = this.items && this.items[0] || {} as any;
        }
        this.listTitles = listTitles;
      }
      this.requestFinish = true
      this.showSkeleton = false;
    },
    clearAgencyCenterState() {
      this.isRefresh = false;
      this.izInvoke = false;
      this.showSkeleton = false;
      this.items = [];
      this.item = {} as any;
      this.listTitles = [] as any;
      this.index = 0;
      this.changeTaskTodoList = false;
    }
  }
})




