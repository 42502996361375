import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import 'amfe-flexible/index';
import '@/assets/iconfont/iconfont.css'
import Vconsole from 'vconsole'
import Vant from 'vant'
import { setupJsBridge } from '@/utils/jsBridge.ts'
import 'vant/lib/index.css';
import BaiduMap from 'vue-baidu-map-3x'
import {parseQueryString} from "@/utils/utils.ts";

const env = import.meta.env.VITE_NODE_ENV;

(!['hadianProd', 'production'].includes(env))  ?  new Vconsole() : void(0);
setupJsBridge()
const queryString = parseQueryString(window.location.href)
if (queryString && queryString['platform']) {
    localStorage.setItem('platform', queryString['platform'])
}
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
const app = createApp(App);
app.use(BaiduMap, {
    // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
    ak: 'IHmiwbRIlAZSR9z2RPN0myiexZhN0LUg',
    // v:'2.0',  // 默认使用3.0
    // type: 'WebGL' // ||API 默认API  (使用此模式 BMap=BMapGL)
  });
app
.use(Vant)
.use(router)
.use(pinia)
.mount('#app')
