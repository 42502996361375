import { defineStore } from "pinia";

export const useStageFlowList = defineStore(
  'stageList',
  {
    state: () => {
      return {
        stageListState: {
          scrollTop: 0,
          queryConditon: null as any,
          pageNo: 1,
          refreshing: false,
          finished: false,
          loading: false,
          dataList: [] as any,
          showSkeleton: false,
          defaultObjectName: '全部',
          defaultSelectType: 1,
          totalsTab: [] as any,
          totalListNumber: 0,
          izInvoke: false,
          objectId: '',
          selectObjectId: '-1',
          isTabRefresh: false,
          isRefresh: false,
          objectFieldsList: [] as any,
          showConditionList: [] as any, // 缓存普通筛选的条件
          doneSubmitParams: {
            processType: 8,
            conditionDTOList: [],
            pageNo: 1,
            pageSiz: 10,
            searchValue: '',
            showFieldList: [],
            source: 2
          },
          processListParams: {
            processType: 8,
            conditionDTOList: [],
            objectId: "-1",
            pageNo: 1,
            pageSiz: 10,
            searchValue: '',
            showFieldList: [],
            source: 2
          }
        }
      }
    },
    actions: {
      clearStageListState() {
        // businessListState.defaultObjectName = ''
         // stageListState.defaultObjectName = ''
         this.stageListState.objectId = ''
         this.stageListState.selectObjectId = '-1'
         this.stageListState.isRefresh = false;
         this.stageListState.isTabRefresh = false;
         this.stageListState.objectFieldsList = []
         // stageListState.showConditionList = [] as any // 缓存普通筛选的条件
         this.stageListState.defaultSelectType = 1
         this.stageListState.totalsTab = [] as any
         this.stageListState.totalListNumber = 0;
         this.stageListState.dataList = [] as any;
         this.stageListState.izInvoke = false;
      }
    }
  }
);