import { defineStore } from 'pinia';

export const useTimeList = defineStore('timeList', {
    state: () => {
        return {
            timeList: {
                searchTime: [] as any
            }
        };
    },
    actions: {
        clearTimeList() {
            this.timeList.searchTime = [];
        }
    },
})