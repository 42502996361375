import { createRouter, createWebHashHistory } from "vue-router";

const notRefreshRoute = ['sign','reportHelper','location','company','companydetail', 'historPriceList','extern-web', 'projectTaskPreview','objectDetail']


const router = createRouter({
    history: createWebHashHistory(),
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 }
        }
    },
    routes: [
        {
            path: '/',
            name: '/',
            component: () => import('../views/login/empty.vue'),
            meta: {
                title: ''
            }
        },
        {
            path: '/login',
            name: '/login',
            component: () => import('../views/login/index.vue'),
            meta: {
                title: '登录'
            }
        },
        {
            path: '/application/extern-web',
            name: 'extern-web',
            component: () => import('../views/login/extern-web/index.vue'),
            meta: {
                title: '外部网页'
            }
        },
        {
            path: '/views/cusTabbar/index',
            name: 'cusTabbarIndex',
            component: () => import('../views/cusTabbar/index.vue'),
            meta: {
                title: '首页',
                keepAlive: true
            },
            beforeEnter: (to:any, from:any, next:Function) => {
                if(!notRefreshRoute.includes(from.name)){
                    to.query.update = true;
                }
                next();
            }
        },
        {
            path: '/user/reset-password',
            name: '/user/reset-password',
            component: () => import('../views/user/reset-password/index.vue'),
            meta: {
                title: '重置密码'
            }
        },
        {
            path: '/views/message/index',
            name: '/views/message/index',
            component: () => import('../views/message/index.vue'),
            meta: {
                title: '消息'
            }
        },
        {
            path: '/views/message/message-detail',
            name: '/views/message/message-detail',
            component: () => import('../views/message/message-detail.vue'),
            meta: {
                title: '消息'
            }
        },
        {
            path: '/views/mine/index',
            name: '/views/mine/index',
            component: () => import('../views/mine/index.vue'),
            meta: {
                title: '我的'
            }
        },
        {
            path: '/example',
            name: '/example',
            component: () => import('../views/example.vue'),
            meta: {
                title: '测试'
            }
        },
        {
            path: '/views/object/detail/index/:objectId/:recordId',
            name: 'objectDetail',
            component: () => import('../views/object/detail/index.vue'),
            meta: {
                title: '详情页',
                keepAlive: true
            },
            beforeEnter: (to, from, next) => {
                // notRefreshRoute.push('objectNew','objectEdit','objecChildNew')
                if(!notRefreshRoute.includes(from.name)){
                    to.query.update = true;
                }
                next();
            },
            children:[
                {
                    path: '/views/object/detail/child/index/:objectId/:recordId',
                    name: 'objecChildDetail',
                    component: () => import('../views/object/detail/index.vue'),
                    meta: {
                        title: '详情页',
                        keepAlive: false
                    },
                },
            ]
        },
        {
            path: '/views/object/new/index/:objectId/:businessId',
            name: 'objectNew',
            component: () => import('../views/object/new/index.vue'),
            meta: {
                title: '新建页',
                keepAlive: true
            },
            beforeEnter: (to, from, next) => {
                if(!notRefreshRoute.includes(from.name)){
                    to.query.update = true;
                }
                next();
            },
            children:[
                {
                    path: '/views/object/new/child/index/:objectId/:businessId',
                    name: 'objecChildNew',
                    component: () => import('../views/object/new/index.vue'),
                    meta: {
                        title: '新建页',
                        keepAlive: false
                    },
                },
            ]
        },
        {
            path: '/views/object/new/sign/:id',
            name: 'sign',
            component: () => import('../views/object/new/sign.vue'),
            meta: {
                title: '签名',
            },
        },

        {
            path: '/views/object/edit/index/:objectId/:recordId',
            name: 'objectEdit',
            component: () => import('../views/object/edit/index.vue'),
            meta: {
                title: '编辑页',
                keepAlive: true
            },
            beforeEnter: (to, from, next) => {
                if(!notRefreshRoute.includes(from.name)){
                    to.query.update = true;
                }
                next();
            },
            children:[
                {
                    path: '/views/object/edit/child/index/:objectId/:recordId',
                    name: 'objecChildEdit',
                    component: () => import('../views/object/edit/index.vue'),
                    meta: {
                        title: '编辑页',
                        keepAlive: false
                    },
                },
            ]
        },
        {
            path: '/views/object/drafts/:objectId/:title',
            name: 'drafts',
            component: () => import('../views/object/drafts.vue'),
            meta: {
                title: '草稿箱'
            }
        },
        {
            path: '/views/object/list/index',
            name: 'objectList',
            component: () => import('../views/object/list/index.vue'),
            meta: {
                title: '列表页',
                keepAlive: true
            },
            beforeEnter: (to:any, from:any, next:Function) => {
                if(!notRefreshRoute.includes(from.name)){
                    to.query.update = true;
                }
                next();
            }
            // props: { default: true, sidebar: false }
        },
        {
            path: '/views/object/list/search',
            name: '/views/object/list/search',
            component: () => import('../views/object/list/search.vue'),
            meta: {
                title: '筛选条件'
            },
            // props: { default: true, sidebar: false }
        },
        {
            path: '/views/task-todo/index',
            name: '/views/task-todo/index',
            component: () => import('../views/task-todo/index.vue'),
            meta: {
                title: '任务代办'
            }
        },
        {
            path: '/views/application/agency-center/index/:isApplication',
            name: 'agencyCenterIndex',
            component: () => import('../views/agency-center/index.vue'),
            meta: {
                title: '待办中心',
                keepAlive: true
            },
            props: true
        },
        {
            path: '/views/business-flow-todo/search',
            name: '/views/business-flow-todo/search',
            component: () => import('../views/business-flow-todo/search.vue'),
            meta: {
                title: '设置筛选条件'
            },
        },
       
        {
            path: '/views/map/index',
            name: '/views/map/index',
            component: () => import('../views/map/index.vue'),
            meta: {
                title: '地图'
            }
        },
        {
            path: '/views/approve/index/:isApplication/:isReturn',
            name: '/views/approve/index',
            component: () => import('../views/approve/index.vue'),
            meta: {
                title: '审批'
            },
            props: true
        },
        {
            path: '/views/application/index',
            name: '/views/application/index',
            component: () => import('../views/application/index.vue'),
            meta: {
                title: '应用'
            }
        },
        {
            path: '/views/mine/personal-center',
            name: '/views/mine/personal-center',
            component: () => import('../views/mine/personal-center.vue'),
            meta: {
                title: '个人中心'
            }
        },
        {
            path: '/views/mine/offline-setting',
            name: '/views/mine/offline-setting',
            component: () => import('../views/mine/offline-setting.vue'),
            meta: {
                title: '离线设置'
            }
        },
        {
            path: '/views/mine/pending-data',
            name: '/views/mine/pending-data',
            component: () => import('../views/mine/pending-data.vue'),
            meta: {
                title: '待处理数据'
            }
        },
        {
            path: '/views/mine/update-password',
            name: '/views/mine/update-password',
            component: () => import('../views/mine/update-password.vue'),
            meta: {
                title: '修改密码'
            }
        },
        {
            path: '/views/mine/about-app',
            name: '/views/mine/about-app',
            component: () => import('../views/mine/about-app.vue'),
            meta: {
                title: '关于应用'
            }
        },
        {
            path: '/views/business-process/business-process/:recordId/:processInstanceId/:processName',
            name: 'business-process',
            component: () => import('../views/business-process/business-process.vue'),
            meta: {
                title: '业务流程'
            }
        },
        {
            path: '/views/company/index',
            name: 'company',
            component: () => import('../views/company/index.vue'),
            meta: {
                title: '工商查询'
            }
        },
        {
            path: '/views/company/detail',
            name: 'companydetail',
            component: () => import('../views/company/detail.vue'),
            meta: {
                title: '工商详情'
            }
        },
        {
            path: '/views/company/more',
            name: '/views/company/more',
            component: () => import('../views/company/more.vue'),
            meta: {
                title: '工商信息查看更多'
            }
        },
        {
            path: '/views/report/index',
            name: '/views/report/index',
            component: () => import('../views/report/index.vue'),
            meta: {
                title: '汇报中心'
            }
        },
        {
            path: '/views/report/helper/index',
            name: 'reportHelper',
            component: () => import('../views/report/helper/index.vue'),
            meta: {
                title: '汇报助手'
            }
        },
        {
            path: '/views/team-members/index',
            name: 'team-members-index',
            component: () => import('../views/team-members/index.vue'),
            meta: {
                title: '团队成员'
            }
        },
        {
            path: '/views/team-members/select-team-member',
            name: 'select-team-member',
            component: () => import('../views/team-members/select-team-member.vue'),
            meta: {
                title: '选择团队成员'
            }
        },
        {
            path: '/views/approval-comp-detail/index',
            name: 'approval-comp-detail',
            component: () => import('../views/approval-comp-detail/index.vue'),
            meta: {
                title: '审批详情'
            }
        },
        {
            path: '/components/comp/old-stage/stage-detail',
            name: 'old-stage-detail',
            component: () => import('../components/comp/old-stage/stage-detail.vue'),
            meta: {
                title: '阶段详情'
            }
        },
        {
            path: '/views/operation-record/index',
            name: 'operation-record',
            component: () => import('../views/operation-record/index.vue'),
            meta: {
                title: '操作记录'
            }
        },
        {
            path: '/views/object/new/location',
            name: 'location',
            component: () => import('../views/object/new/location.vue'),
            meta: {
                title: '选择定位'
            }
        },
        {
            path: '/views/history-price-list/index',
            name: 'historPriceList',
            component: () => import('../components/comp/history-price/history-list/index.vue'),
            meta: {
                title: '历史报价'
            }
        },
        {
            path: '/views/project-task-preview/index',
            name: 'projectTaskPreview',
            component: () => import('../views/project-task-preview/index.vue'),
            meta: {
                title: '任务预览'
            }
        },

    ]
})

export default router;