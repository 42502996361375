/* eslint-disable prefer-rest-params */
import difference from "lodash/difference";
import intersection from "lodash/intersection";
import DateUtils from "./DateUtils";
import dayjs from 'dayjs';
import {AMAP_WEB_KEY} from '@/utils/config';
import type {FieldListItem} from "@/components/fields/types/fieldList.ts";
import cloneDeep from "lodash/cloneDeep";

/**
 * 判断条件是否成立
 *
 * @param condition 要处理的条件
 * @param filterLogic
 * @param customLogic
 * @param recordId
 * @param userInfo
 */
export function judgingCondition(condition: any, filterLogic: number, customLogic: string, {
    recordId,
    userInfo
}: any = {}) {
    if (condition.length > 0) {
        const result: any = [];
        condition.forEach((item: any) => {
            let value: any, targetValue: any; // value: 规则设置的值 targetValue: 用户录入的值
            if (Array.isArray(item.value)) {
                // 如果值得类型是数组 单选、多选、查找、查找员工
                if (item.fieldType === 13 || item.fieldType === 14) {
                    // 查找、查找员工
                    value = item.value.map((f: any) => {
                        let v = f.id || f.recordId;
                        if (v === "{SYSTEM_CURRENT_USER}") {
                            // 当前用户
                            v = userInfo ? userInfo.userId : "";
                        } else if (v === "{SYSTEM_CURRENT_RECORD}") {
                            // 当前记录
                            v = recordId;
                        }
                        return v;
                    });
                    targetValue = item.targetValue.id ? [item.targetValue.id] : [];
                } else if (item.fieldType === 32 || item.fieldType === 33) {
                    // 关联多选、关联用户多选
                    value = (item.value || []).map((v: any) => v.recordId);
                    targetValue = (item.targetValue?.label.split(',') || []).map((v: any) => v);
                } else if (item.fieldType == 34 || item.fieldType == 35) {
                    value = (item.value || []).map((v: any) => v.id);
                    targetValue = (item.targetValue.label || []).map((v: any) => v);
                } else {
                    // 单选、多选
                    value = item.value ? item.value.map((v: any) => v.id) : [];
                    targetValue = item.targetValue?.id ? item.targetValue.id.split(",") : [];
                }
            } else if (item.value instanceof Object) {
                // 如果值得类型是对象  只可能是字段的情况(目前只有单行文本、多行文本、单选、多选、数字、关联)
                if (item.fieldType === 9) {
                    // 单选
                    value = item.value.label ? item.value.label : "";
                    targetValue = item.targetValue.label ? item.targetValue.label : "";
                } else if (item.fieldType === 10) {
                    // 多选
                    value = item.value.label ? item.value.label.split(",") : [];
                    targetValue = item.targetValue.label ? item.targetValue.label.split(",") : [];
                } else if (item.fieldType === 13) {
                    // 关联
                    value = item.value.id;
                    targetValue = item.targetValue.id;
                } else {
                    value = item.value.label;
                    targetValue = item.targetValue.label;
                }
            } else if (item.fieldType === 2) {
                // 富文本
                value = item.value;
                targetValue = getHtmlPlainText(item.targetValue.label);
            } else if (item.fieldType === 25 || item.fieldType === 26) {
                // 图片、文件
                value = item.value;
                targetValue = item.targetValue && item.targetValue.id ? item.targetValue.id.split(",").length : '';
            } else if (item.fieldType === 6) {
                // 数字
                value = isNaN(item.value) ? item.value : Number(item.value);
                targetValue = (isNaN(item.targetValue.label) || isEmpty(item.targetValue.label)) ? item.targetValue.label : Number(item.targetValue.label);
            } else if (item.fieldType === 12 || item.fieldType === 20) {
                // 计算公式、汇总字段
                if (item.currentField.resultForm === 1) {
                    // 结果显示数字类型
                    value = isNaN(item.value) ? item.value : Number(item.value);
                    targetValue = (isNaN(item.targetValue.label) || isEmpty(item.targetValue.label)) ? item.targetValue.label : Number(item.targetValue.label);
                } else if (item.currentField.resultForm === 2) {
                    // 结果显示百分数类型
                    if (item.value && item.value.indexOf("%") > -1) {
                        value = item.value.replace("%", "");
                        value = (isNaN(value) || isEmpty(value)) ? "" : Number(value) / 100;
                    } else {
                        value = (isNaN(item.value) || isEmpty(item.value)) ? item.value : Number(item.value);
                    }

                    if (item.targetValue && item.targetValue.label.indexOf("%") > -1) {
                        targetValue = item.targetValue.label.replace("%", "");
                        targetValue = (isNaN(item.targetValue.label) || isEmpty(item.targetValue.label)) ? "" : Number(targetValue) / 100;
                    } else {
                        targetValue = (isNaN(item.targetValue.label) || isEmpty(item.targetValue.label)) ? item.targetValue.label : Number(item.targetValue.label);
                    }
                } else if (item.currentField.resultForm === 3 || item.currentField.resultForm === 4) {
                    // 日期、日期时间
                    value = item.value ? new Date(item.value).getTime() : "";
                    targetValue = item.targetValue.label ? new Date(item.targetValue.label).getTime() : "";
                } else {
                    value = item.value;
                    targetValue = item.targetValue.label;
                }
            } else if (item.fieldType === 15) {
                // 百分数
                if (item.value && item.value.indexOf("%") > -1) {
                    value = item.value.replace("%", "");
                    value = (isNaN(value) || isEmpty(value)) ? "" : Number(value) / 100;
                } else {
                    value = (isNaN(item.value) || isEmpty(item.value)) ? item.value : Number(item.value);
                }

                if (item.targetValue && item.targetValue.label.indexOf("%") > -1) {
                    targetValue = item.targetValue.label.replace("%", "");
                    targetValue = (isNaN(item.targetValue.label) || isEmpty(item.targetValue.label)) ? "" : Number(targetValue) / 100;
                } else {
                    targetValue = (isNaN(item.targetValue.label) || isEmpty(item.targetValue.label)) ? item.targetValue.label : Number(item.targetValue.label);
                }
            } else if (item.fieldType === 31) {
                // 网址
                value = item.value;
                targetValue = item.targetValue && item.targetValue.label ? item.targetValue.label.length : '';
                // targetValue = item.targetValue.label;
            } else if (item.fieldType === 38) {
                // 金额
                value = isNaN(item.value) ? item.value : Number(item.value);
                targetValue = (isNaN(item.targetValue.label) || isEmpty(item.targetValue.label)) ? item.targetValue.label : Number(item.targetValue.label);
            } else if (item.fieldType === 39) {
                // 布尔值
                value = item.value + "";
                targetValue = item.targetValue.label + "";
            } else {
                // 其他情况 单行文本、日期、日期时间、自动编号、签名、邮箱、电话 这里面应有(需要特殊处理) 多行文本、富文本
                value = item.value;
                targetValue = item.targetValue?.label;
            }
            if (isEmpty(targetValue) && item.operator === 38) {
                // 操作符是不为空，但为空了直接置成false
                result.push(false);
            } else {
                switch (item.operator) {
                    case 1: {

                        if (isEmpty(value) || isEmpty(targetValue)) {
                            result.push(false);
                        } else {
                            if (Array.isArray(targetValue) && Array.isArray(value)) {
                                // 都是数组
                                result.push(difference(targetValue, value).length === 0 && difference(value, targetValue).length === 0);
                            } else {
                                //
                                result.push(targetValue == value);
                            }
                        }
                    }
                        break;
                    case 2: {

                        // 不等于
                        if (isEmpty(value) || isEmpty(targetValue)) {
                            result.push(false);
                        } else {
                            if (Array.isArray(targetValue) && Array.isArray(value)) {
                                // 都是数组
                                result.push(difference(targetValue, value).length > 0 || difference(value, targetValue).length > 0);
                            } else {
                                //
                                result.push(targetValue !== value);
                            }
                        }
                    }
                        break;
                    case 3: {
                        // 任意等于
                        if (isEmpty(value) || isEmpty(targetValue)) {
                            result.push(false);
                        } else {
                            if (Array.isArray(targetValue) && Array.isArray(value)) {
                                // 都是数组
                                result.push(intersection(targetValue, value).length > 0);
                            } else {
                                // 这里面应该存在电话、邮箱
                                targetValue = (item.targetValue.label || []).map((t: any) => t.value);
                                result.push(targetValue.indexOf(value) > -1);
                            }
                        }
                    }
                        break;
                    case 4: {
                        // 任意包含 只可能电话、邮箱
                        targetValue = (item.targetValue.label || []).map((t) => Object.values(t)[0]);

                        const list = targetValue.filter((t: any) => {
                            return t.indexOf(value) > -1;
                        });
                        result.push(list.length > 0);
                    }
                        break;
                    case 5: {
                        // 任意不等于
                        if (isEmpty(value)) {
                            result.push(false);
                        } else {
                            if (Array.isArray(targetValue) && Array.isArray(value)) {
                                // 都是数组
                                if (targetValue.length === 0) {
                                    result.push(true);
                                } else {
                                    result.push(difference(targetValue, value).length > 0);
                                }
                            } else {
                                // 这里面应该存在电话、邮箱
                                targetValue = (item.targetValue.label || []).map((t: any) => t.value);
                                result.push(targetValue.indexOf(value) === -1);
                            }
                        }
                    }
                        break;
                    case 6: {
                        // 任意不包含 只可能电话、邮箱
                        targetValue = (item.targetValue.label || []).map((t: any) => t.value);
                        const list = targetValue.filter((t: any) => {
                            return t.indexOf(value) === -1;
                        });
                        result.push(list.length > 0);
                    }
                        break;
                    case 7: {
                        // 包含
                        if (isEmpty(value) || isEmpty(targetValue)) {
                            result.push(false);
                        } else {
                            result.push(targetValue.indexOf(value) > -1);
                        }
                    }
                        break;
                    case 8: {
                        // 不包含
                        if (isEmpty(value)) {
                            result.push(false);
                        } else {
                            result.push(targetValue.indexOf(value) === -1);
                        }
                    }
                        break;
                    case 9: {
                        // 开头等于
                        if (isEmpty(value) || isEmpty(targetValue)) {
                            result.push(false);
                        } else {
                            result.push(new RegExp("^" + value).test(targetValue));
                        }
                    }
                        break;
                    case 10: {
                        // 开头不等于
                        if (isEmpty(value)) {
                            result.push(false);
                        } else {
                            result.push(!new RegExp("^" + value).test(targetValue));
                        }
                    }
                        break;
                    case 11: {
                        // 任意开头等于 只可能电话、邮箱
                        targetValue = (item.targetValue.label || []).map((t: any) => t.value);

                        const list = targetValue.filter(() => {
                            return new RegExp("^" + value).test(targetValue);
                        });
                        result.push(list.length > 0);
                    }
                        break;
                    case 12: {
                        // 任意开头不等于 只可能电话、邮箱
                        targetValue = (item.targetValue.label || []).map((t: any) => t.value);

                        const list = targetValue.filter(() => {
                            return new RegExp("^" + value).test(targetValue);
                        });
                        result.push(list.length === 0);
                    }
                        break;
                    case 13: {
                        // 结尾等于
                        if (isEmpty(value) || isEmpty(targetValue)) {
                            result.push(false);
                        } else {
                            result.push(new RegExp(value + "$").test(targetValue));
                        }
                    }
                        break;
                    case 14: {
                        // 结尾不等于
                        if (isEmpty(value) || isEmpty(targetValue)) {
                            result.push(false);
                        } else {
                            result.push(!new RegExp(value + "$").test(targetValue));
                        }
                    }
                        break;
                    case 15: {
                        // 任意结尾等于 只可能电话、邮箱
                        targetValue = (item.targetValue.label || []).map((t: any) => t.value);

                        const list = targetValue.filter(() => {
                            return new RegExp(value + "$").test(targetValue);
                        });
                        result.push(list.length > 0);
                    }
                        break;
                    case 16: {
                        // 任意结尾不等于 只可能电话、邮箱
                        targetValue = (item.targetValue.label || []).map((t: any) => t.value);

                        const list = targetValue.filter(() => {
                            return new RegExp(value + "$").test(targetValue);
                        });
                        result.push(list.length === 0);
                    }
                        break;
                    case 17: {
                        // 大于
                        if (isEmpty(value) || isEmpty(targetValue)) {
                            result.push(false);
                        } else {
                            if (item.fieldType === 4 || item.fieldType === 5) {
                                value = dayjs(value).format('YYYY/MM/DD HH:mm:ss');
                                targetValue = dayjs(targetValue).format('YYYY/MM/DD HH:mm:ss');
                                // 日期、日期时间
                                value = new Date(value).getTime();
                                targetValue = new Date(targetValue).getTime();
                            }

                            result.push(targetValue > value);
                        }
                    }
                        break;
                    case 18: {
                        // 大于或等于
                        if (isEmpty(value) || isEmpty(targetValue)) {
                            result.push(false);
                        } else {
                            if (item.fieldType === 4 || item.fieldType === 5) {
                                value = dayjs(value).format('YYYY/MM/DD HH:mm:ss');
                                targetValue = dayjs(targetValue).format('YYYY/MM/DD HH:mm:ss');
                                // 日期、日期时间
                                value = new Date(value).getTime();
                                targetValue = new Date(targetValue).getTime();
                            }

                            result.push(targetValue >= value);
                        }
                    }
                        break;
                    case 19: {
                        // 小于
                        if (isEmpty(value) || isEmpty(targetValue)) {
                            result.push(false);
                        } else {
                            if (item.fieldType === 4 || item.fieldType === 5) {
                                value = dayjs(value).format('YYYY/MM/DD HH:mm:ss');
                                targetValue = dayjs(targetValue).format('YYYY/MM/DD HH:mm:ss');
                                // 日期、日期时间
                                value = new Date(value).getTime();
                                targetValue = new Date(targetValue).getTime();
                            }

                            result.push(targetValue < value);
                        }
                    }
                        break;
                    case 20: {
                        // 小于或等于
                        if (isEmpty(value) || isEmpty(targetValue)) {
                            result.push(false);
                        } else {
                            if (item.fieldType === 4 || item.fieldType === 5) {
                                value = dayjs(value).format('YYYY/MM/DD HH:mm:ss');
                                targetValue = dayjs(targetValue).format('YYYY/MM/DD HH:mm:ss');
                                // 日期、日期时间
                                value = new Date(value).getTime();
                                targetValue = new Date(targetValue).getTime();
                            }

                            result.push(targetValue <= value);
                        }
                    }
                        break;
                    case 21: {
                        // 昨天
                        if (isEmpty(targetValue)) {
                            result.push(false);
                        }
                        const dayStr = new Date(targetValue).setHours(0, 0, 0, 0);
                        const today = new Date().setHours(0, 0, 0, 0);
                        result.push(dayStr - today === -86400000);
                    }
                        break;
                    case 22: {
                        // 今天
                        if (isEmpty(targetValue)) {
                            result.push(false);
                        }
                        const dayStr = new Date(targetValue).setHours(0, 0, 0, 0);
                        const today = new Date().setHours(0, 0, 0, 0);
                        result.push(dayStr - today === 0);
                    }
                        break;
                    case 23: {
                        // 明天
                        if (isEmpty(targetValue)) {
                            result.push(false);
                        }
                        const dayStr = new Date(targetValue).setHours(0, 0, 0, 0);
                        const today = new Date().setHours(0, 0, 0, 0);
                        result.push(dayStr - today === 86400000);
                    }
                        break;
                    case 24: {
                        // 今后7天
                        const today = new Date(DateUtils.getYearAndMontAndDateStr(new Date()) + " 00:00:00").getTime(),
                            startTime = today - 1,
                            endTime = today + 7 * 24 * 60 * 60 * 1000;
                        targetValue = new Date(targetValue).getTime();

                        result.push(targetValue > startTime && targetValue < endTime);
                    }
                        break;
                    case 25: {
                        // 最近7天
                        const today = new Date(DateUtils.getYearAndMontAndDateStr(new Date()) + " 00:00:00").getTime(),
                            startTime = today - 6 * 24 * 60 * 60 * 1000 - 1,
                            endTime = today + 24 * 60 * 60 * 1000;
                        targetValue = new Date(targetValue).getTime();

                        result.push(targetValue > startTime && targetValue < endTime);
                    }
                        break;
                    case 26: {
                        // 上周
                        const date = new Date().getDay() || 7;
                        const today = new Date(DateUtils.getYearAndMontAndDateStr(new Date()) + " 00:00:00").getTime(),
                            startTime = today - (date - 1 + 7) * 24 * 60 * 60 * 1000 - 1,
                            endTime = today - (date - 1) * 24 * 60 * 60 * 1000;
                        targetValue = new Date(targetValue).getTime();

                        result.push(targetValue > startTime && targetValue < endTime);
                    }
                        break;
                    case 27: {
                        // 本周
                        const date = new Date().getDay() || 7;
                        const today = new Date(DateUtils.getYearAndMontAndDateStr(new Date()) + " 00:00:00").getTime(),
                            startTime = today - (date - 1) * 24 * 60 * 60 * 1000 - 1,
                            endTime = today + (8 - date) * 24 * 60 * 60 * 1000;
                        targetValue = new Date(targetValue).getTime();

                        result.push(targetValue > startTime && targetValue < endTime);
                    }
                        break;
                    case 28: {
                        // 下周
                        const date = new Date().getDay() || 7;
                        const today = new Date(DateUtils.getYearAndMontAndDateStr(new Date()) + " 00:00:00").getTime(),
                            startTime = today + (8 - date) * 24 * 60 * 60 * 1000 - 1,
                            endTime = today + (8 - date + 7) * 24 * 60 * 60 * 1000;
                        targetValue = new Date(targetValue).getTime();

                        result.push(targetValue > startTime && targetValue < endTime);
                    }
                        break;
                    case 29: {
                        // 上月
                        const date = new Date(DateUtils.getPreMonth(new Date())).toISOString().substring(0, 7);
                        targetValue = targetValue.substring(0, 7);

                        result.push(targetValue == date);
                    }
                        break;
                    case 30: {
                        // 本月
                        const date = new Date().toISOString().substring(0, 7);
                        targetValue = targetValue.substring(0, 7);

                        result.push(targetValue == date);
                    }
                        break;
                    case 31: {
                        // 下月
                        const date = new Date(DateUtils.getNextMonth(new Date())).toISOString().substring(0, 7);
                        targetValue = targetValue.substring(0, 7);
                        result.push(targetValue == date);
                    }
                        break;
                    case 32: {
                        // 去年
                        const date = new Date(new Date().getFullYear() - 1 + "").getFullYear();
                        targetValue = new Date(targetValue).getFullYear();

                        result.push(targetValue == date);
                    }
                        break;
                    case 33: {
                        // 今年
                        const date = new Date().getFullYear();
                        targetValue = new Date(targetValue).getFullYear();
                        result.push(targetValue == date);
                    }
                        break;
                    case 34: {
                        // 明年
                        const date = new Date(new Date().getFullYear() + 1 + "").getFullYear();
                        targetValue = new Date(targetValue).getFullYear();

                        result.push(targetValue == date);
                    }
                        break;
                    case 35: {
                        // 晚于(包含当天)
                        const startTime = new Date().getTime() - 1;

                        result.push(targetValue > startTime);
                    }
                        break;
                    case 36: {
                        // 早于(包含当天)
                        const startTime = new Date().getTime() + 24 * 60 * 60 * 1000;

                        result.push(targetValue < startTime);
                    }
                        break;
                    case 37: {
                        // 为空
                        result.push(isEmpty(targetValue));
                    }
                        break;
                    case 38: {
                        // 不为空
                        result.push(!isEmpty(targetValue));
                    }
                        break;
                    case 39: {

                        // 等于(字段值)

                        if (isEmpty(value) || isEmpty(targetValue)) {
                            result.push(false);
                        } else {
                            if (Array.isArray(targetValue) && Array.isArray(value)) {
                                // 都是数组
                                result.push(difference(targetValue, value).length === 0 && difference(value, targetValue).length === 0);
                            } else {
                                //
                                result.push(targetValue == value);
                            }
                        }
                    }
                        break;
                    case 40: {
                        // 不等于(字段值)
                        if (isEmpty(value)) {
                            result.push(false);
                        } else {
                            if (Array.isArray(targetValue) && Array.isArray(value)) {
                                // 都是数组
                                result.push(difference(targetValue, value).length > 0 || difference(value, targetValue).length > 0);
                            } else {
                                //
                                result.push(targetValue !== value);
                            }
                        }
                    }
                        break;
                    case 41: {
                        // 大于(字段值)
                        if (isEmpty(value) || isEmpty(targetValue)) {
                            result.push(false);
                        } else {
                            if (item.fieldType === 4 || item.fieldType === 5) {
                                // 日期、日期时间
                                value = new Date(value).getTime();
                                targetValue = new Date(targetValue).getTime();
                            }

                            result.push(targetValue > value);
                        }
                    }
                        break;
                    case 42: {
                        // 大于等于(字段值)
                        if (isEmpty(value) || isEmpty(targetValue)) {
                            result.push(false);
                        } else {
                            if (item.fieldType === 4 || item.fieldType === 5) {
                                // 日期、日期时间
                                value = new Date(value).getTime();
                                targetValue = new Date(targetValue).getTime();
                            }

                            result.push(targetValue >= value);
                        }
                    }
                        break;
                    case 43: {
                        // 小于(字段值)
                        if (isEmpty(value) || isEmpty(targetValue)) {
                            result.push(false);
                        } else {
                            if (item.fieldType === 4 || item.fieldType === 5) {
                                // 日期、日期时间
                                value = new Date(value).getTime();
                                targetValue = new Date(targetValue).getTime();
                            }

                            result.push(targetValue < value);
                        }
                    }
                        break;
                    case 44: {
                        // 小于等于(字段值)
                        if (isEmpty(value) || isEmpty(targetValue)) {
                            result.push(false);
                        } else {
                            if (item.fieldType === 4 || item.fieldType === 5) {
                                // 日期、日期时间
                                value = new Date(value).getTime();
                                targetValue = new Date(targetValue).getTime();
                            }

                            result.push(targetValue <= value);
                        }
                    }
                        break;
                    case 58: {

                        if (value === undefined) {
                            result.push(false);
                            return
                        }
                        // 发生变更
                        if (Array.isArray(targetValue) && Array.isArray(value)) {
                            // 都是数组
                            result.push(difference(targetValue, value).length > 0 || difference(value, targetValue).length > 0);
                        } else {
                            //
                            result.push(targetValue != value);
                        }
                    }
                        break;
                    case 64: {
                        // 数量小于 只有可能是图片、文件、网址字段
                        if (isEmpty(value) || isNaN(value)) {
                            result.push(false);
                        } else {
                            result.push(targetValue < value);
                        }
                    }
                        break;
                    case 65: {
                        // 数量大于 只有可能是图片、文件、网址字段
                        if (isEmpty(value) || isNaN(value)) {
                            result.push(false);
                        } else {
                            result.push(targetValue > value);
                        }
                    }
                        break;
                    case 66: {
                        // 数量小于等于 只有可能是图片、文件、网址字段
                        if (isEmpty(value) || isNaN(value)) {
                            result.push(false);
                        } else {
                            result.push(targetValue == value);
                        }
                    }
                        break;
                    case 68: {
                        // 包含(字段值)
                        if (isEmpty(value) || isEmpty(targetValue)) {
                            result.push(false);
                        } else {
                            if (Array.isArray(targetValue) && Array.isArray(value)) {
                                // 都是数组
                                result.push(intersection(value, targetValue).length > 0 && intersection(value, targetValue).length === value.length);
                            } else {
                                //
                                result.push(targetValue.indexOf(value) > -1);
                            }
                        }
                    }
                        break;
                    case 69: {

                        // 不包含(字段值)
                        if (isEmpty(value) || isEmpty(targetValue)) {
                            result.push(false);
                        } else {
                            if (Array.isArray(targetValue) && Array.isArray(value)) {
                                // 都是数组
                                result.push(difference(value, targetValue).length > 0);
                            } else {
                                //
                                result.push(targetValue.indexOf(value) === -1);
                            }
                        }
                    }
                        break;
                    case 70: {
                        // 大于当前
                        if (item.fieldType === 4 || item.currentField.resultForm === 3) {
                            // 日期
                            value = dayjs().format('YYYY/MM/DD HH:mm:ss');
                            value = new Date(value).getTime();
                        } else if (item.fieldType === 5 || item.currentField.resultForm === 4) {
                            // 日期时间
                            value = dayjs().format('YYYY/MM/DD HH:mm:ss');
                            value = new Date(value).getTime();
                        }
                        targetValue = dayjs(targetValue).format('YYYY/MM/DD HH:mm:ss');
                        targetValue = new Date(targetValue).getTime();

                        result.push(targetValue > value);
                    }
                        break;
                    case 71: {
                        // 小于当前
                        if (item.fieldType === 4 || item.currentField.resultForm === 3) {
                            // 日期
                            value = dayjs().format('YYYY/MM/DD HH:mm:ss');
                            value = new Date(value).getTime();
                        } else if (item.fieldType === 5 || item.currentField.resultForm === 4) {
                            // 日期时间
                            value = dayjs().format('YYYY/MM/DD HH:mm:ss');
                            value = new Date(value).getTime();
                        }
                        targetValue = dayjs(targetValue).format('YYYY/MM/DD HH:mm:ss');
                        targetValue = new Date(targetValue).getTime();

                        result.push(targetValue < value);
                    }
                        break;
                    case 72: {
                        // 大于等于当前
                        if (item.fieldType === 4 || item.currentField.resultForm === 3) {
                            // 日期
                            value = dayjs().format('YYYY/MM/DD HH:mm:ss');
                            value = new Date(value).getTime();
                            // value = new Date(DateUtils.getYearAndMontAndDateStr(new Date())).getTime();
                        } else if (item.fieldType === 5 || item.currentField.resultForm === 4) {
                            // 日期时间
                            value = dayjs().format('YYYY/MM/DD HH:mm:ss');
                            value = new Date(value).getTime();
                        }
                        targetValue = dayjs(targetValue).format('YYYY/MM/DD HH:mm:ss');
                        targetValue = new Date(targetValue).getTime();

                        result.push(targetValue >= value);
                    }
                        break;
                    case 73: {
                        // 小于等于当前
                        if (item.fieldType === 4 || item.currentField.resultForm === 3) {
                            // 日期
                            value = dayjs().format('YYYY/MM/DD HH:mm:ss');
                            value = new Date(value).getTime();
                            // value = new Date(DateUtils.getYearAndMontAndDateStr(new Date())).getTime();
                        } else if (item.fieldType === 5 || item.currentField.resultForm === 4) {
                            // 日期时间
                            value = dayjs().format('YYYY/MM/DD HH:mm:ss');
                            value = new Date(value).getTime();
                        }
                        targetValue = dayjs(targetValue).format('YYYY/MM/DD HH:mm:ss');
                        targetValue = new Date(targetValue).getTime();

                        result.push(targetValue <= value);
                    }
                        break;
                }
            }
        });

        if (filterLogic === 1) {
            // 满足全部条件
            return result.every((res: any) => res);
        } else if (filterLogic === 2) {
            // 满足任意条件
            return result.some((res: any) => res);
        } else if (filterLogic === 3) {
            // 自定义逻辑
            if (customLogic) {
                customLogic = customLogic.replace(/and/g, "&&").replace(/or/g, "||");
                const len = result.length;
                for (let i = len - 1; i >= 0; i--) {
                    customLogic = customLogic.replace(new RegExp(i + 1 + "", "g"), result[i]);
                }
                try {
                    return eval(customLogic);
                } catch (error) {
                    return false;
                }
            }
            return false;
        }
    }

    return false;
}

/**
 * 取出html字符串的内容
 * @param html_str html字符串
 * @returns String
 */
export function getHtmlPlainText(html_str: any) {
    const re = new RegExp("<[^<>]+>", "g");
    const text = html_str.replace(re, "");
    return text.replace(/\\n/g, '')
}

export const isEmpty = (value: any) => {
    return value === "undefined" || value === undefined || value === null || value === "" || (Array.isArray(value) && value.length === 0);
};


/**
 * 随机生成数字
 *
 * 示例：生成长度为 12 的随机数：randomNumber(12)
 * 示例：生成 3~23 之间的随机数：randomNumber(3, 23)
 *
 * @param1 最小值 | 长度
 * @param2 最大值
 * @return int 生成后的数字
 */
export function randomNumber(args: any) {
    // 生成 最小值 到 最大值 区间的随机数
    const random = (min: number, max: number) => {
        return Math.floor(Math.random() * (max - min + 1) + min);
    };
    if (arguments.length === 1) {
        const [length] = arguments;
        // 生成指定长度的随机数字，首位一定不是 0
        const nums = [...Array(length).keys()].map((i) => (i > 0 ? random(0, 9) : random(1, 9)));
        return parseInt(nums.join(""));
    } else if (arguments.length >= 2) {
        const [min, max] = arguments;
        return random(min, max);
    } else {
        return Number.NaN;
    }
}

const weekMap = new Map([
    [1, '周一'],
    [2, '周二'],
    [3, '周三'],
    [4, '周四'],
    [5, '周五'],
    [6, '周六'],
    [0, '周日'],
])

// 通过时间获取星期几
export function getWeekByDate(dateStr: string) {
    if (!dateStr) return;
    const parsedDateTime = dayjs(dateStr);
    // 获取星期几的数字表示（0: 星期日, 1: 星期一, ..., 6: 星期六）
    const dayOfWeekNumber = parsedDateTime.day();
    return weekMap.get(dayOfWeekNumber);
}

/**
 * 操作记录展示字段值
 * @param text
 * @param record 字段列表
 * @param fieldType 字段列表
 * @param apiName 字段列表
 */
export function convertValue(record: any, fieldType: number, apiName: string) {
    let val = record[apiName];
    const str = '更新了';
    if ([2, 25, 18, 26].includes(fieldType)) {//富文本字段, 图片，签名， 文件
        return str + `【${record.fieldLabel}】`;
    } else if ([7, 8].includes(fieldType)) {
        let text = '';
        record[apiName]?.map((item: any, index: number) => {
            text += (item.showName + ':' + item.showValue + (index < record[apiName].length - 1 ? ',' : ''))
        })
        return text;
    } else {
        if (record[apiName]) {
            if (Array.isArray(record[apiName])) { // 邮箱、电话、网址、关联多选、关联用户多选会是数组
                if (record[apiName]?.length > 0) {
                    record[apiName].forEach((item, index) => {
                        if (index == 0) {
                            val = item.showValue
                        } else {
                            val = val + ',' + item.showValue
                        }
                    })
                    // if ([32, 33].indexOf(fieldType) > -1) {// 关联多选、关联用户多选
                    //     val = record[apiName][0].showValue;
                    // } else {
                    //     val = record[apiName][0].showValue;
                    // }
                } else {
                    return ''
                }
            } else if (typeof (record[apiName]) === 'object') {  // 如果这个属性是对象 将把showName 返回
                if (fieldType === 9 && record[apiName].url) { // 存在url属性的只有单选并且配置了显示图标
                    val = `<image src="${record[apiName].url}" style="height: 23px; width: 23px; margin-right: 2px;" />` + (record[apiName].showValue || '');
                } else if (fieldType === 26 && record[apiName].showName) {// 存在showName的数据只可能是文件字段
                    val = record[apiName].showName.split(',')[0];
                } else if (fieldType === 14) {// 关联用户
                    val = record[apiName].showValue || "";
                } else if (fieldType === 38) {
                    if (record.izUpperCase) {
                        return record[apiName].showValue;
                    } else {
                        return record[apiName].showValue ? "CNY  " + record[apiName].showValue : record[apiName].showValue;
                    }
                }
                if (fieldType === 41) {  // 生命状态
                    return lifeStatusList.filter((item: any) => item.id === record[apiName].id)[0];
                } else {
                    return record[apiName].showValue;
                }
            } else {
                if ([6, 12, 20].indexOf(fieldType) > -1) { // 是数字、计算公式、汇总字段类型 需要显示单位
                } else if ([1].indexOf(fieldType) > -1) {// 当行文本(可能存在数字类型)
                }
            }
        }
        return val;
    }

}

// 生命状态值显示
const lifeStatusList = [
    {id: "2", name: "未生效", background: "#F3F3F3", color: "#ACACAC"},
    {id: "3", name: "审批中", background: "#F0F1FF", color: "#2559F1"},
    {id: "1", name: "已生效", background: "#E8F8F2", color: "#00A870"},
    {id: "4", name: "变更中", background: "#FCE7DA", color: "#ED7B2F"}
]

/**
 * 处理后的value可直接用
 * @param value
 * @param field 字段配置
 */
export function getFieldValue(value: any, field: any) {
    const fieldType = field?.fieldType;
    if (value) {
        if (Array.isArray(value)) { // 邮箱、电话、网址、关联多选、关联用户多选会是数组
            if (value?.length > 0) {
                if ([32, 33].indexOf(fieldType) > -1) {// 关联多选、关联用户多选
                    return value[0].showValue;
                } else {
                    return value[0].showValue;
                }
            } else {
                return ''
            }
        } else if (value && typeof (value) === 'object') {  // 如果这个属性是对象 将把showName 返回
            if (fieldType === 9 && value.url) { // 存在url属性的只有单选并且配置了显示图标
                return value.showValue;
            } else if (fieldType === 26 && value.showName) {// 存在showName的数据只可能是文件字段
                return value.showName?.split(',')[0];
            } else if (fieldType === 12) {
                if (field.izUpperCase) {
                    return value.showValue;
                } else {
                    return value.showValue ? "CNY  " + value.showValue : value.showValue;
                }
            } else if (fieldType === 20) {

                if (field.izUpperCase) {
                    return value.showValue;
                } else {
                    return value.showValue ? "CNY  " + value.showValue : value.showValue;
                }
            } else if (fieldType === 14) {// 关联用户
                return value.showValue || "";
            } else if (fieldType === 38) {
                if (field.izUpperCase) {
                    return value.showValue;
                } else {
                    return value.showValue ? "CNY  " + value.showValue : value.showValue;
                }
            } else if (fieldType === 41) {
                return lifeStatusList.filter((item: any) => item.id === value.id)[0];
            }
            return value.showValue;
        } else {
            if ([6, 12, 20].indexOf(fieldType) > -1) { // 是数字、计算公式、汇总字段类型 需要显示单位
                return field.unit ? value + field.unit : value;
            } else if (fieldType === 15) {
                return value + '%';
            } else if (fieldType === 4) {
                let val = value
                if (val) {
                    if (field?.dayShowTypeMobile == 2 && val.length > 7) {
                        val = val.slice(0, 7)
                    }
                }
                return val
            } else if ([1].indexOf(fieldType) > -1) {// 当行文本(可能存在数字类型)
                return value + ''
            } else if (fieldType === 5) {
                if (field.timeShowType !== 2) {
                    return value;
                } else {
                    const newDate = value.substr(0, 16);
                    return newDate
                }
            }
        }
    }
    return value
}

/**
 * 获得tag的颜色值
 * @param value
 * @param field
 */
export function getTagColor(value: any, field: any) {
    const result = {backgroundColor: '', color: ''}
    const color = getFieldColor(value, field)
    if (color) {
        result.color = color;
        result.backgroundColor = color + '33';
    } else {
        result.color = '#2559F1';
        result.backgroundColor = '#2559F11A';
    }
    return result;
}


/**
 * 获得右上角状态标签的颜色值
 * @param value
 * @param field
 */
export function getStatusColor(value: any, field: any) {
    const result = {backgroundColor: '', color: ''}
    const color = getFieldColor(value, field)
    if (color) {
        result.backgroundColor = color;
        result.color = '#ffffff';
    } else {
        result.backgroundColor = '#2559F1';
        result.color = '#ffffff';
    }
    return result;
}


/**
 * 列表页获得单选选项颜色
 * @param value
 * @param field
 */
export function getFieldColor(value: any, field: any) {
    if (value) {
        const finditem = field?.tagList?.find((item: any) =>
            item.id == value.id
        )
        if (finditem) {
            return finditem.tagColor
        } else {
            return undefined
        }
    } else return undefined
}

export const getColor = (name: string, tagList: any) => {
    let color = undefined;
    if (!tagList?.length) return color;
    tagList?.map((tag: any) => {
        if (tag.name === name) {
            color = tag.tagColor;
        }
    })
    return color;
}

// 将json数据转为url拼接形式
export function makeUrl(json: any) {
    let search = '';
    if (json instanceof Object) {
        search = Object.keys(json).map(function (key: string) {
            return key + "=" + json[key];
        }).join("&");
    }
    return search;

}

// 获取文本宽度
export const getActualWidthOfChars = (text: string, options: any) => {
    const family = "PingFang SC";
    const {size = 28} = options;
    const canvas = document.createElement("canvas");
    const ctx: any = canvas.getContext("2d");
    ctx.font = `${size}rpx ${family}`;
    return ctx.measureText(text).width;
}

/**
 * 随机生成数字
 *
 * 示例：生成长度为 12 的随机数：randomNumber(12)
 * 示例：生成 3~23 之间的随机数：randomNumber(3, 23)
 *
 * @param1 最小值 | 长度
 * @param2 最大值
 * @return int 生成后的数字
 */
// export function randomNumber(numbers: number) {
//   // 生成 最小值 到 最大值 区间的随机数
//   const random = (min:number, max:number) => {
//     return Math.floor(Math.random() * (max - min + 1) + min);
//   };
//   if (arguments.length === 1) {
//     // 生成指定长度的随机数字，首位一定不是 0
//     const nums = [...Array(numbers).keys()].map((i) => (i > 0 ? random(0, 9) : random(1, 9)));
//     return parseInt(nums.join(""));
//   }
// }

/*
 * 拼接筛选条件
 */

export function splicingFilterCondition({filterCondition, targetValues, operators, level, excludeLevel}: any) {
    if (!filterCondition || filterCondition.length === 0) return [];
    filterCondition = JSON.parse(JSON.stringify(filterCondition));
    operators = operators || [39, 40, 41, 42, 43, 44, 68, 69];
    excludeLevel = excludeLevel || [];
    return filterCondition.map((condition: any) => {
        let valueLevel = level;
        if (valueLevel === undefined && operators.indexOf(condition.operator) > -1) {
            valueLevel = condition.value.length - 1;
        }
        if (operators.indexOf(condition.operator) > -1 && condition.value.length - 1 === valueLevel && excludeLevel.indexOf(valueLevel) === -1) {
            // 这些操作类型 需要转换
            const field: any = targetValues.find((f: any) => f.id === condition.value[valueLevel].id);
            if (field) {
                if ([9, 39].indexOf(field.fieldType) > -1) {
                    // 单选、布尔值
                    if (field.fieldType === 9) {
                        condition.value = field.value?.label ? [{name: field.value.label}] : [];
                    } else {
                        condition.value = field.value?.label && field.value.label.length > 1 ? [{name: field.value.label[0]}] : [];
                    }
                } else if ([10].indexOf(field.fieldType) > -1) {
                    // 多选
                    condition.value = field.value?.label.split(",").map((name: string) => {
                        return {name};
                    });
                } else if ([13].indexOf(field.fieldType) > -1) {
                    let value = field.value.id, name;
                    if (Array.isArray(value)) {
                        value = value[0].id;
                        name = value[0].showValue;
                    }
                    // 关联
                    condition.value = field.value.id ? [{recordId: value, recordName: name}] : "";
                } else if ([38].indexOf(field.fieldType) > -1) {
                    // 金额
                    condition.value = field.value.id;
                } else {
                    // 其他字段类型
                    condition.value = field.value.label;
                }
            } else {
                condition.value = "";
            }

            if (condition.operator === 39) {
                condition.operator = 1;
            } else if (condition.operator === 40) {
                condition.operator = 2;
            } else if (condition.operator === 41) {
                condition.operator = 17;
            } else if (condition.operator === 42) {
                condition.operator = 18;
            } else if (condition.operator === 43) {
                condition.operator = 19;
            } else if (condition.operator === 44) {
                condition.operator = 20;
            } else if (condition.operator === 68) {
                condition.operator = 7;
            } else if (condition.operator === 69) {
                condition.operator = 8;
            }
        }
        return condition;
    });
}

/**
 * 节流 防止暴力点击
 * @param fn 执行的防止暴力点击的方法
 * @param delay 时间（多长时间内只可点击一次）
 */
export function throttle(fn: Function, delay: number) {
    let timer: number | null = null;

    return function (...args: any[]) {
        if (!timer) {
            fn(...args);
            timer = setTimeout(() => {
                timer = null;
            }, delay);
        }
    };
}

export function throttleLevel(fn: Function, wait: number, immediate: boolean) {
    let timeout: number | null;
    let lastRun = 0;

    function wrapper(this: any) {
        const context = this;
        const args = arguments;
        if (timeout) return;

        if (immediate) {
            const callNow = Date.now() - lastRun >= wait;
            if (callNow) {
                fn.apply(context, args);
                lastRun = Date.now();
            } else {
                timeout = setTimeout(() => {
                    fn.apply(context, args);
                    lastRun = Date.now();
                    timeout = null;
                }, wait - (Date.now() - lastRun));
            }
        } else {
            const callNow = Date.now() - lastRun >= wait;
            if (!callNow) {
                return;
            }
            fn.apply(context, args);
            lastRun = Date.now();
        }
    }

    return wrapper;
}

/**
 * 防抖
 * @param fn 防抖的方法
 * @param delay 在当前时间只执行最后一次
 */
export function debounce(fn: Function, delay: number) {
    let timer: number | null = null;

    return function (...args: any[]) {
        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(() => {
            fn(...args);
            timer = null;
        }, delay);
    };
}

/**
 * 过滤要保存的map种的不是本对象的字段
 * @param map
 * @param fieldList
 */
export const filterExcessData = (map: any, fieldList: FieldListItem[]) => {
    //过滤非本对象字段
    if (map && fieldList) {
        for (const key in map) {
            const find = fieldList.find((item: any) => item.id == key);
            if (!find) {
                delete map[key];
            }
        }
    }

    try {
        for (const field of fieldList.filter((item: any) => item.fieldType == 4 || item.fieldType == 5)) {
            if (map[field?.id]) {
                map[field?.id] = map[field?.id].replace(/\//g, '-');
            }
        }
    } catch (e) {
        console.log(e)
    }

    //过滤不保存字段
    /*if (map && fieldList) {
        //37:主从字段
        const filterData = fieldList.filter((item) => item.fieldType == 37)
        for (const key in map) {
          const find= filterData.find((item)=> item.id == key);
            if (find) {
                delete map[key];
            }
        }
    }*/

    return map;
}

export const validateMap = (map: any, fieldList: FieldListItem[]) => {
    let success = true,msg = '';
    for(let key in map) {
        const field = fieldList.find((item) => item.id == key);
        if([25,30].includes(field?.fieldType) && !map[key] && field?.required){
            success = false;
            msg = `${field.name}不能为空`;
        }
    }
    return {
        success,
        msg
    }
}

export const dataURLtoFil = (dataurl, filename) => {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
}

export const parseQueryString = (queryString: string) => {
    const params: any = {};
    queryString?.split('?')[1]?.split('&')?.forEach((item) => {
        const [key, value] = item?.split('=');
        //判断value是否包含android或者ios
        if (value?.includes('android')) {
            params[key] = decodeURIComponent('android');
        } else if (value?.includes('iOS')) {
            params[key] = decodeURIComponent('iOS');
        } else {
            params[key] = decodeURIComponent('unknow');
        }
    });
    return params;
}

/**
 * 地址转经纬度
 * @param address
 */
export const addressToLatLng = (address: string) => {
    return new Promise((resolve, reject) => {
        //创建地址解析器实例
        const BMapGL = window.BMapGL;
        const myGeo = new BMapGL.Geocoder();
        // 将地址解析结果显示在地图上，并调整地图视野
        myGeo.getPoint(address, (point: any) => {
            if (point) {
                resolve(point);
            } else {
                reject("")
            }
        }, address)
    })

}
export const latLngToAddress = (longitude: string, latitude: string) => {
    return new Promise((resolve) => {
        const BMapGL = window.BMapGL;
        const geoc = new BMapGL.Geocoder();
        const point = new BMapGL.Point(longitude, latitude);
        geoc.getLocation(point, function (rs: any) {
            resolve(rs);
        });
    })
}


// 解决获取表单未操作，model取不到值的情况
export const getAllModel = (model: any,fieldList:any) => { 
    const allFieldIds = fieldList.map(el => el.id);
    // const allFieldIds = Array.prototype.slice.call(document.getElementsByClassName('__field_item__')).map(field => field.attributes.fieldid.value);
    allFieldIds.forEach(item => {
        // res[item] = null;
        // if(__model.hasOwnProperty(item)){
        //     res[item] = __model[item];
        // }
        if(!model.hasOwnProperty(item)){
            model[item] = null;
        }
    })
}

//校验所有字段必填
export const validateAllField = (model:any,fieldList:any) => {
    const allFieldIds = Array.prototype.slice.call(document.getElementsByClassName('__field_item__')).map(field => field.attributes.fieldid.value)
    let success = true,msg = '';
    for(let i = 0; i < allFieldIds.length; i++){
        const _item = allFieldIds[i];
        const field = fieldList.find((f:any) => f.id == _item);
        if (field) {
            if(field?.fieldType == 39){
                if(field && field.required && !model[_item] && typeof model[_item] != 'boolean'){
                    success = false;
                    msg = `${field.name}不能为空`;
                    break;
                }
            }else if (field?.fieldType == 1){
                if(model[_item] && field?.options?.formatCheck){
                    const checkPass = new RegExp(field.options.formatCheckReg).test(model[_item] || '')
                    if(!checkPass){
                        success = false;
                        msg = `${field.options.formatCheckMsg}`;
                        break;
                    }
                }
            }else{
                if(field && field.required && !model[_item]){
                    success = false;
                    msg = `${field.name}不能为空`;
                    break;
                }
            }
        }
    }
    return {
        success,
        msg
    }

    for(let key in model){
        const field = fieldList.find((f:any) => f.id == key);
        if (field) {
            if(field?.fieldType == 39){
                if(field && field.required && !model[key] && typeof model[key] != 'boolean'){
                    success = false;
                    msg = `${field.name}不能为空`;
                }
            }else{
                if(field && field.required && !model[key]){
                    success = false;
                    msg = `${field.name}不能为空`;
                }
            }
        }
    }
    return {
        success,
        msg
    }
}

const is = (val: unknown, type: string) => {
    return toString.call(val) === `[object ${type}]`
}

const isArray = (val: any): val is Array<any> => {
    return val && Array.isArray(val)
}

const isString = (val: unknown): val is string => {
    return is(val, 'String')
}

const isObject = (val: any): val is Record<any, any> => {
    return val !== null && is(val, 'Object')
}

export const isEmptyValue =<T = unknown> (val: T): val is T => {
    if (!val) {
        return true;
    }

    if (isArray(val) || isString(val))
        return val.length === 0
    if (val instanceof Map || val instanceof Set)
        return val.size === 0
    if (isObject(val)){
        if(Object.keys(val).length === 0) {
            return true;
        } else if(!val?.id) {
            return true;
        }
    }
    return false
}

export function hexToRgb(hex) {
  // 去除#号（如果有）
  hex = hex.replace("#", "");
  
  // 将16进制颜色值拆分成r、g、b三个部分
  var r = parseInt(hex.substring(0, 2), 16);
  var g = parseInt(hex.substring(2, 4), 16);
  var b = parseInt(hex.substring(4, 6), 16);
  
  // 返回RGB颜色值
  return "rgba(" + r + ", " + g + ", " + b + ","+'0.125'+")";
}

//写一个超过指定数量后省略末尾三个点的函数
export function ellipsis(str: string, num: number) {
    return str.length > num ? str.slice(0, num) + '...' : str;
}