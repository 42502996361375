import { defineStore } from 'pinia';
import { getObjectFieldTag, queryFilterRecordByTabId, queryObjectPower, queryViewByType, queryListData } from '@/api/object';
import { getFieldValue, getColor } from '@/utils/utils';
import { showDialog } from 'vant';
import { useTimeList } from './useTimeList';

const edit = '4b5a73b9a90209b09196ce99';
const del = '9a53e731a3f0b32feb0d70af';
const btnKey = '62bad05f08838e7b1a6740c6';
// 创建一个Pinia实例
export const registerObjectListBaseStore = defineStore('objectListStore', {
    state: () => {
        return {
            objectListState: {
                izLoading: true,
                selectTabIndex: 0,
                freshWorkbench: false,
                scrollTop: 0,
                showContentSkeleton: false,
                refreshing: false,
                loading: false,
                finished: false,
                isChangingTab: false,
                idFieldKeyReal: '',
                refresherEnabled: true, // 是否开启下拉刷新
                idList: [] as any,
                pagingDealListFunc: null as any,
                pagingReloadFunc: null as any,
                isRefresh: false, // 是否加载标识
                triggered: false, // 下拉刷新出发标识
                viewId: '' as any,
                objectFilterId: '',//默认展示的视图Id
                currentRecord: {} as any,
                objectInfo: {} as any,
                objectId: '' as any,
                tabId: '' as any, // 通过此id查出对象视图
                objectFilterDBRefList: [] as any, // 列表页视图配置
                componentUiList: [] as any,
                attrsField: [] as any,  // 属性标签
                statusField: {} as any, // 状态
                themeField: {} as any, // 主题字段
                themeImage: {} as any, // 主题图片
                contentFields: {} as any, // 列表展示哪些字段
                objectFieldsList: [] as any,
                fieldList: [] as any,
                dataList: [] as any,
                total: 0 as number,
                btnGroupList: [] as any, // 列表操作按钮
                title: '',
                tabs: [] as any,  // 视图
                defaultSelectTab: {} as any, // 默认选中的视图
                power: {} as any, // 权限
                idFieldKey: '', // 主键字段
                primaryFieldId: '', // 主字段id
                lockFieldKey: '', // 锁定对应的键
                showThemeImage: false, // 是否展示主题图片
                interfaceFlag: true, // 是否单独调用接口，单独调用会返回一个没有响应式的list
                viewFastFilterList: [] as any, // 快速筛选字段
                objectFilterStyle: 1,
                showConditionList: [] as any, // 缓存普通筛选的条件
                noPower: false,
                showSkeleton: true,
                isReport: false,
                izInvoke: false,
            },
            objectListSearchState: {
                viewId: '',
                baseConditionList: [] as any,
                customLogic: '',
                filterLogic: 1,
                objectFilterId: '',
                pageNo: 1,
                pageSize: 20,
                searchCondition: '',
                fastFilterConditionList: [] as any
            },
            fastFilterState: {
                selectViewFastFilterList: [] as any
            }
        };
    },
    actions: {
        setIds(objectId?: any, tabId?: any, viewId?: any) {
            this.objectListState.objectId = objectId;
            this.objectListState.tabId = tabId;
            this.objectListState.viewId = viewId;
        },
        // 获取配置信息
        getConfig(interfaceFlag?: boolean) {
            const timeInfo = useTimeList();
            timeInfo.clearTimeList();
            if (!(typeof interfaceFlag === 'undefined')) {    // 参数不传递默认为true
                this.objectListState.interfaceFlag = interfaceFlag;
            }
            const params = {
                object_id: this.objectListState.objectId
            }
            const filterParams = {
                tab_id: this.objectListState.tabId,
                objectfilter_id: this.objectListState.objectFilterId || ''
            }
            const viewParams = {
                object_id: this.objectListState.objectId,
                view_type: 10,
                view_id: this.objectListState.viewId
            }
            let time = new Date().getTime();
            timeInfo.timeList.searchTime.push(time);
            const p = Promise.all(
                [
                    getObjectFieldTag(params),
                    queryFilterRecordByTabId(filterParams),
                    queryObjectPower(params),
                    queryViewByType(viewParams)
                ]
            );
            
            p.then((res: any) => {
                console.log(res, 'res');
                if (time === timeInfo.timeList.searchTime[timeInfo.timeList.searchTime.length - 1]) {
                    if (res[0].data) {
                        if (!this.objectListState.title) {
                            this.objectListState.title = res[0].data.name || ''
                        }
                        this.objectListState.objectFieldsList = res[0].data.fieldList?.filter((item: any) => item.powerType !== 1) || []
                        this.objectListState.fieldList = this.objectListState.objectFieldsList;
                        this.objectListState.idFieldKeyReal = res[0].data.idFieldKey;
                        this.objectListState.idFieldKey = res[0].data.idFieldKey; // 这个变量后续要改变，改了之后是一个recordID, 后期需要优化统一参数
                        this.objectListState.lockFieldKey = res[0].data.lockFieldKey
                        this.objectListState.objectInfo.idFieldKey = res[0].data.idFieldKey;
                        this.objectListState.objectInfo.primaryFieldId = res[0].data.primaryFieldId;
                        this.objectListState.objectInfo.apiName = res[0].data.apiName;
                        this.objectListState.objectInfo.fieldList = this.objectListState.objectFieldsList;
                        this.objectListState.primaryFieldId = res[0].data.primaryFieldId;
                    }
                    if (res[2].data) {
                        this.objectListState.power = res[2].data
                    }
                    if (res[3].data?.length) {
                        if (res[3].data[0]?.viewFastFilterList?.length) {
                            const list = [] as any
                            res[3].data[0]?.viewFastFilterList.map((fastItem: any) => {
                                res[0].data.fieldList?.map((item: any) => {
                                    if (fastItem.fieldDBRef.id === item.id) {
                                        list.push(item)
                                    }
                                })
                            })
                            this.objectListState.viewFastFilterList = list;
                        } else {
                            this.objectListState.viewFastFilterList = []
                        }
                        if (res[3].data[0]?.webJson) {
                            const componentUiList = JSON.parse(res[3].data[0]?.webJson)?.componentUiList || []
                            this.objectListState.componentUiList = componentUiList;
                            this.objectListState.themeImage = componentUiList[0]?.options.themeImage;
                            this.objectListState.showThemeImage = !!componentUiList[0]?.options.themeImage?.ids;
                            this.objectListState.themeField = componentUiList[0]?.options.themeField;
                            this.objectListState.statusField = componentUiList[0]?.options.statusField;
                            this.objectListState.attrsField = componentUiList[0]?.options.attrsField;
                            this.objectListState.contentFields = componentUiList[1]?.options;
                            this.objectListState.btnGroupList = componentUiList[2]?.options?.btnGroupList
                            this.objectListState.viewId = res[3].data[0].id;
                        }
                        if (res[3].data[0]?.objectFilterStyle) {
                            this.objectListState.objectFilterStyle = res[3].data[0]?.objectFilterStyle
                        }
                        this.objectListState.objectFilterDBRefList = res[3].data[0]?.objectFilterDBRefList;
                    }

                    // 把配置的字段对应的字段类型找出来，只执行一次
                    this.getFieldValueByConfig();
                    if(res[1].code === 120002) {
                        showDialog({
                            title: '提示',
                            message: '请优先进行筛选',
                        })
                        this.objectListState.noPower = true;
                    } else if (!res[1].data) {   // 进入列表页是否有权限
                        this.objectListState.noPower = true;
                    } else {
                        this.objectListState.noPower = false;
                    }
                    if (res[1].data?.viewObjectFilterList?.length && !this.objectListState.noPower) {
                        const list = [] as any;
                        this.objectListState.objectFilterDBRefList.map((uiItem: any) => {
                            res[1].data?.viewObjectFilterList?.map((powerItem: any) => {
                                if (uiItem.id === powerItem.id) {
                                    list.push({
                                        ...powerItem,
                                        value: powerItem.id
                                    });
                                }
                            })
                        })
                        if (list?.length) {
                            const id = list.filter((item: any) => item.id == this.objectListState.objectFilterId)[0]?.id;
                            const filter = list.filter((item: any) => item.id == this.objectListState.objectFilterId)[0];
                            this.objectListSearchState.objectFilterId = this.objectListSearchState.objectFilterId && !this.objectListState.freshWorkbench ? this.objectListSearchState.objectFilterId : (id || list[0]?.id);
                            this.objectListState.defaultSelectTab = this.objectListState.defaultSelectTab?.id && !this.objectListState.freshWorkbench ? this.objectListState.defaultSelectTab : (filter || list[0]);
                            // console.log(this.objectListState.defaultSelectTab, 'this.objectListState.defaultSelectTab');

                            if (this.objectListState.isReport) {
                                const reportList = [] as any
                                for (let index = 0; index < list.length; index++) {
                                    const element = list[index];
                                    if (element.apiName == 'writeReport' || element.apiName == 'supplementaryReport') {
                                        continue
                                    }
                                    reportList.push(element)
                                }

                                this.objectListState.defaultSelectTab = this.objectListState.defaultSelectTab?.id ? this.objectListState.defaultSelectTab : reportList[0];
                                this.objectListSearchState.objectFilterId = this.objectListSearchState.objectFilterId ? this.objectListSearchState.objectFilterId : reportList[0].id;
                                this.objectListState.tabs = reportList
                            } else {
                                this.objectListState.tabs = list
                            }
                            this.objectListState.selectTabIndex = this.objectListState.tabs.findIndex((item: any) => item.id == this.objectListSearchState.objectFilterId)
                            // console.log(this.objectListState.selectTabIndex, 'this.objectListState.selectTabIndex');
                            if (this.objectListState.selectTabIndex === -1) { // 说明该视图配置已不存在，需要去掉
                                this.objectListState.defaultSelectTab = filter || list[0];
                                this.objectListSearchState.objectFilterId = id || list[0]?.id;
                                this.objectListState.selectTabIndex = 0;
                            }
                            this.objectListState.freshWorkbench = false;
                            // 默认查询第一个视图数据接口
                            if (this.objectListState.interfaceFlag && !this.objectListState.isReport) {
                                // getDataListByFilterId();
                                // console.log(this.objectListState.refreshing, 'this.objectListState.refreshing');

                                if (this.objectListState.refreshing) {
                                    this.objectListState.dataList = [];
                                    this.objectListState.refreshing = false;
                                }
                                if (res[1]?.data?.pageReturn?.records?.length < 20) {
                                    this.objectListState.finished = true;
                                }
                                this.setListData(res[1]?.data?.pageReturn);
                            } else if (!this.objectListState.interfaceFlag) {
                                let listPromise = this.getDataListByFilterId();
                                listPromise.then((list: any) => {
                                    if (list?.length < 20) {
                                        this.objectListState.finished = true;
                                    } else {
                                        this.objectListState.finished = false;
                                    }
                                    this.objectListState.refreshing = false;
                                    this.objectListState.loading = false;
                                    this.objectListState.dataList = list;
                                })
                            } else if (this.objectListState.isReport) {
                                this.getDataListByFilterId();
                            }
                        }
                    }
                    this.objectListState.showContentSkeleton = false;
                }
            }).catch(err => {
                this.objectListState.showContentSkeleton = false;
                console.log(err, 'err-objectList');
            })
        },
        getFieldValueByConfig() {
            this.objectListState.objectFieldsList.map((item: any) => {
                if (item.id === this.objectListState.themeImage?.ids) {
                    this.objectListState.themeImage = item
                }
                if (item.id === this.objectListState.themeField?.ids) {
                    this.objectListState.themeField = item
                }
                if (item.id === this.objectListState.statusField?.ids) {
                    this.objectListState.statusField = item
                }
                if (this.objectListState.attrsField?.length) { // 展示的属性标签字段，最多3个
                    this.objectListState.attrsField.map((attrItem: any, index: number) => {
                        if (attrItem.ids === item.id) {
                            this.objectListState.attrsField[index] = item
                        }
                    })
                }
                // console.log(this.objectListState.attrsField, 'this.objectListState.attrsField');

                if (this.objectListState.contentFields) {  // 展示的内容字段，最多10个，取前10个
                    if (item.id === this.objectListState.contentFields.field1?.ids) {
                        this.objectListState.contentFields.field1 = {
                            ...item,
                            isAll: this.objectListState.contentFields.field1.isAll
                        }
                    } else if (item.id === this.objectListState.contentFields.field2?.ids) {
                        this.objectListState.contentFields.field2 = {
                            ...item,
                            isAll: this.objectListState.contentFields.field2.isAll
                        }
                    } else if (item.id === this.objectListState.contentFields.field3?.ids) {
                        this.objectListState.contentFields.field3 = {
                            ...item,
                            isAll: this.objectListState.contentFields.field3.isAll
                        }
                    } else if (item.id === this.objectListState.contentFields.field4?.ids) {
                        this.objectListState.contentFields.field4 = {
                            ...item,
                            isAll: this.objectListState.contentFields.field4.isAll
                        }
                    } else if (item.id === this.objectListState.contentFields.field5?.ids) {
                        this.objectListState.contentFields.field5 = {
                            ...item,
                            isAll: this.objectListState.contentFields.field5.isAll
                        }
                    } else if (item.id === this.objectListState.contentFields.field6?.ids) {
                        this.objectListState.contentFields.field6 = {
                            ...item,
                            isAll: this.objectListState.contentFields.field6.isAll
                        }
                    } else if (item.id === this.objectListState.contentFields.field7?.ids) {
                        this.objectListState.contentFields.field7 = {
                            ...item,
                            isAll: this.objectListState.contentFields.field7.isAll
                        }
                    } else if (item.id === this.objectListState.contentFields.field8?.ids) {
                        this.objectListState.contentFields.field8 = {
                            ...item,
                            isAll: this.objectListState.contentFields.field8.isAll
                        }
                    } else if (item.id === this.objectListState.contentFields.field9?.ids) {
                        this.objectListState.contentFields.field9 = {
                            ...item,
                            isAll: this.objectListState.contentFields.field9.isAll
                        }
                    } else if (item.id === this.objectListState.contentFields.field10?.ids) {
                        this.objectListState.contentFields.field10 = {
                            ...item,
                            isAll: this.objectListState.contentFields.field10.isAll
                        }
                    }
                }
            })
        },
        // 根据视图查询数据接口
        async getDataListByFilterId() {
            this.objectListSearchState.viewId = this.objectListState.viewId;
            const res: any = await queryListData(this.objectListSearchState);
            this.objectListState.idList = res.data?.idList || [];
            this.objectListState.isChangingTab = false;
            this.objectListState.showSkeleton = false;
            if (res.success) {
                this.objectListState.total = res.data.total;
                if (this.objectListState.interfaceFlag) {
                    this.dealCommonData(res.data.records);
                } else {
                    const list = this.dealCommonData(res.data.records);
                    return Promise.resolve(list);
                }
            }
        },
        dealCommonData(toDealList: any) {
            const list = [] as any;
            toDealList?.map((item: any) => {
                const obj = {
                    id: getFieldValue(item[this.objectListState.idFieldKeyReal], 1),
                    themeField: getFieldValue(item[this.objectListState.themeField.id], this.objectListState.themeField),
                    themeImage: getFieldValue(item[this.objectListState.themeImage.id], this.objectListState.themeImage),
                    statusField: {
                        field: this.objectListState.statusField,
                        value: getFieldValue(item[this.objectListState.statusField.id], this.objectListState.statusField),
                        color: getColor(getFieldValue(item[this.objectListState.statusField.id], this.objectListState.statusField), this.objectListState.statusField?.tagList)
                    },
                    attrsField: [] as any,
                    contentFields: [] as any,
                    edit: item[edit],
                    del: item[del],
                    isLock: item[this.objectListState.lockFieldKey] === 1,
                    btnGroupList: item[btnKey],
                    recordData: item,
                    ...item
                }
                
                obj.contentFields = [
                    {
                        originallyVal: item[this.objectListState.contentFields.field1.id],
                        field: this.objectListState.contentFields.field1,
                        name: this.objectListState.contentFields.field1.name,
                        value: getFieldValue(item[this.objectListState.contentFields.field1.id], this.objectListState.contentFields.field1)
                    },
                    {
                        originallyVal: item[this.objectListState.contentFields.field2.id],
                        field: this.objectListState.contentFields.field2,
                        name: this.objectListState.contentFields.field2.name,
                        value: getFieldValue(item[this.objectListState.contentFields.field2.id], this.objectListState.contentFields.field2)
                    },
                    {
                        originallyVal: item[this.objectListState.contentFields.field3.id],
                        field: this.objectListState.contentFields.field3,
                        name: this.objectListState.contentFields.field3.name,
                        value: getFieldValue(item[this.objectListState.contentFields.field3.id], this.objectListState.contentFields.field3)
                    },
                    {
                        originallyVal: item[this.objectListState.contentFields.field4.id],
                        field: this.objectListState.contentFields.field4,
                        name: this.objectListState.contentFields.field4.name,
                        value: getFieldValue(item[this.objectListState.contentFields.field4.id], this.objectListState.contentFields.field4)
                    },
                    {
                        originallyVal: item[this.objectListState.contentFields.field5.id],
                        field: this.objectListState.contentFields.field5,
                        name: this.objectListState.contentFields.field5.name,
                        value: getFieldValue(item[this.objectListState.contentFields.field5.id], this.objectListState.contentFields.field5)
                    },
                    {
                        originallyVal: item[this.objectListState.contentFields.field6.id],
                        field: this.objectListState.contentFields.field6,
                        name: this.objectListState.contentFields.field6.name,
                        value: getFieldValue(item[this.objectListState.contentFields.field6.id], this.objectListState.contentFields.field6)
                    },
                    {
                        originallyVal: item[this.objectListState.contentFields.field7.id],
                        field: this.objectListState.contentFields.field7,
                        name: this.objectListState.contentFields.field7.name,
                        value: getFieldValue(item[this.objectListState.contentFields.field7.id], this.objectListState.contentFields.field7)
                    },
                    {
                        originallyVal: item[this.objectListState.contentFields.field8.id],
                        field: this.objectListState.contentFields.field8,
                        name: this.objectListState.contentFields.field8.name,
                        value: getFieldValue(item[this.objectListState.contentFields.field8.id], this.objectListState.contentFields.field8)
                    },
                    {
                        originallyVal: item[this.objectListState.contentFields.field9.id],
                        field: this.objectListState.contentFields.field9,
                        name: this.objectListState.contentFields.field9.name,
                        value: getFieldValue(item[this.objectListState.contentFields.field9.id], this.objectListState.contentFields.field9)
                    },
                    {
                        originallyVal: item[this.objectListState.contentFields.field10.id],
                        field: this.objectListState.contentFields.field10,
                        name: this.objectListState.contentFields.field10.name,
                        value: getFieldValue(item[this.objectListState.contentFields.field10.id], this.objectListState.contentFields.field10)
                    }
                ]
                obj.contentFields.map((contentFieldItem: any) => {
                    if (contentFieldItem.field.fieldType === 9) {
                        contentFieldItem.color = getColor(contentFieldItem.value, contentFieldItem.field?.tagList)
                    }
                })
                obj.attrsField = [
                    {
                        originallyVal: item[this.objectListState.attrsField[0]?.id],
                        field: this.objectListState.attrsField[0],
                        value: getFieldValue(item[this.objectListState.attrsField[0]?.id], this.objectListState.attrsField[0]),
                        color: getColor(getFieldValue(item[this.objectListState.attrsField[0]?.id], this.objectListState.attrsField[0]), this.objectListState.attrsField[0]?.tagList)
                    },
                    {
                        originallyVal: item[this.objectListState.attrsField[1]?.id],
                        field: this.objectListState.attrsField[1],
                        value: getFieldValue(item[this.objectListState.attrsField[1]?.id], this.objectListState.attrsField[1]),
                        color: getColor(getFieldValue(item[this.objectListState.attrsField[1]?.id], this.objectListState.attrsField[1]), this.objectListState.attrsField[1]?.tagList)
                    },
                    {
                        originallyVal: item[this.objectListState.attrsField[2]?.id],
                        field: this.objectListState.attrsField[2],
                        value: getFieldValue(item[this.objectListState.attrsField[2]?.id], this.objectListState.attrsField[2]),
                        color: getColor(getFieldValue(item[this.objectListState.attrsField[2]?.id], this.objectListState.attrsField[2]), this.objectListState.attrsField[2]?.tagList)
                    },
                ];
                obj.attrsField = obj.attrsField?.filter((item: any) => !!item.value);
                list.push(obj)
            })
            if (this.objectListSearchState.pageNo === 1 && list?.length === 0) {
                this.objectListState.dataList = [];
            }
            if (this.objectListState.interfaceFlag) {
                this.objectListState.dataList = [...this.objectListState.dataList, ...list];
            } else {
                return list;
            }
        },

        setListData(data: any) {
            this.objectListSearchState.viewId = this.objectListState.viewId;
            this.objectListState.total = data?.total;
            this.objectListState.idList = data?.idList || [];
            this.dealCommonData(data?.records);
            this.objectListState.showSkeleton = false;
            this.objectListState.loading = false;
        },
        clearObjectList() {
            this.objectListState.izInvoke = false;
            this.objectListState.izLoading = true;
            this.objectListState.selectTabIndex = 0;
            this.objectListState.freshWorkbench = false;
            this.objectListState.scrollTop = 0;
            this.objectListState.showContentSkeleton = false;
            this.objectListState.refreshing = false;
            this.objectListState.loading = false;
            this.objectListState.finished = false;
            this.objectListState.isChangingTab = false;
            this.objectListState.idFieldKeyReal = '';
            this.objectListState.refresherEnabled = true; // 是否开启下拉刷新
            this.objectListState.idList = [];
            this.objectListState.pagingDealListFunc = null;
            this.objectListState.pagingReloadFunc = null;
            this.objectListState.isRefresh = false; // 是否加载标识
            this.objectListState.triggered = false; // 下拉刷新触发标识
            this.objectListState.viewId = '';
            this.objectListState.objectFilterId = ''; // 默认展示的视图Id
            this.objectListState.currentRecord = {};
            this.objectListState.objectInfo = {};
            this.objectListState.objectId = '';
            this.objectListState.tabId = ''; // 通过此id查出对象视图
            this.objectListState.objectFilterDBRefList = []; // 列表页视图配置
            this.objectListState.componentUiList = [];
            this.objectListState.attrsField = []; // 属性标签
            this.objectListState.statusField = {}; // 状态
            this.objectListState.themeField = {}; // 主题字段
            this.objectListState.themeImage = {}; // 主题图片
            this.objectListState.contentFields = {}; // 列表展示哪些字段
            this.objectListState.objectFieldsList = [];
            this.objectListState.fieldList = [];
            this.objectListState.dataList = [];
            this.objectListState.total = 0;
            this.objectListState.btnGroupList = []; // 列表操作按钮
            this.objectListState.title = '';
            this.objectListState.tabs = []; // 视图
            this.objectListState.defaultSelectTab = {}; // 默认选中的视图
            this.objectListState.power = {}; // 权限
            this.objectListState.idFieldKey = ''; // 主键字段
            this.objectListState.primaryFieldId = ''; // 主字段id
            this.objectListState.lockFieldKey = ''; // 锁定对应的键
            this.objectListState.showThemeImage = false; // 是否展示主题图片
            this.objectListState.interfaceFlag = true; // 是否单独调用接口
            this.objectListState.viewFastFilterList = []; // 快速筛选字段
            this.objectListState.objectFilterStyle = 1;
            this.objectListState.showConditionList = []; // 缓存普通筛选的条件
            this.objectListState.noPower = false;
            this.objectListState.showSkeleton = true;
            this.objectListState.isReport = false;
            this.objectListSearchState.viewId = '';
            this.objectListSearchState.baseConditionList = [];
            this.objectListSearchState.customLogic = '';
            this.objectListSearchState.filterLogic = 1;
            this.objectListSearchState.objectFilterId = '';
            this.objectListSearchState.pageNo = 1;
            this.objectListSearchState.pageSize = 20;
            this.objectListSearchState.searchCondition = '';
            this.objectListSearchState.fastFilterConditionList = [];
            this.fastFilterState.selectViewFastFilterList = [];
        },
    },
    // persist: true
});