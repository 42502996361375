import { getAction, postAction } from "../api/http/index";
import type { IObjectField, IQueryListData, IObjectView, IQueryViewParams, IDelParams } from 'src/typings/interface';

// 通过对象主键查询对象+字段+标签
export function getObjectFieldTag(data: IObjectField) {
  return getAction('lc/object/queryAllById/power',  data )
}

// 查询字段
export function queryFieldByObjectId(data: IObjectField) {
  return getAction('lc/field/queryByObjectId',  data)
}

// 查询视图
export function queryFilterRecordByTabId(data: IObjectView) {
  return getAction('lc/list/queryFilterRecordByTabId',  data)
}

// 通过视图去查询列表数据
export function queryListData(data: IQueryListData) {
  return postAction('lc/list/queryListData', data)
}

export const queryViewByType = (data: IQueryViewParams) => {
  return getAction('lc/viewType/queryViewByTypeNew', data);
}

// 对象权限
export function queryObjectPower(data: IObjectField){
    return getAction('lc/sysBtn/queryObjectPower',  data)
}

//业务类型
export const queryEnableList = (data: { objectId: string }) => {
  return getAction('lc/businessType/queryEnableList', data);
}

// 删除该对象下某条数据
export function deleteObjectDataById(data: IDelParams) {
  return postAction('lc/record/deleteObjectDataById', data);
}

// 查询子记录弹框列表数据
export function queryChildListData(data: any) {
  return postAction('lc/relevant/queryRelevantData', data)
}

// 查询视图
export function queryObjectFilterByObjectId(data: IObjectField) {
  return getAction('lc/list/queryObjectFilterByObjectId',  data)
}

//解除子记录绑定
export function unBindChildRecord(data: { linkIds: [string], recordId: string, viewFilterId: string }) {
  return postAction('lc/record/unlink', data)
}