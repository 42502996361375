import {getAction, makeFormData, postAction, UrlSearchParams} from "../api/http/index";

// 获取公钥
export function getPublicKey() {
  return getAction("/auth/account/public_secret");
}

// 登录凭证接口
export function getCredential(parameter?:any) {
  return postAction("/auth/credential", parameter);
}
// 登录 - 滑块验证码校验
export function captchaVerify(parameter:any) {
  return postAction(`/auth/v2/captcha/verify`, parameter);
}
// 登录 - 密码校验
export function pwdVerify(parameter:any) {
  return postAction("auth/v2/pwd/verify", parameter);
}
// 登录 - 重置密码
export function resetPwd(parameter:any) {
  return postAction("/auth/pwd/reset", parameter);
}

// 账号密码登录
export function accountLogin(parameter:any) {
  return postAction("/auth/login", UrlSearchParams(parameter));
}

// 手机号+短信验证码登录
export function phoneLogin(parameter:any) {
  return postAction("/auth/sms_code/verify", parameter);
}

// 获取短信验证码
export function getSmsCaptcha(parameter:any) {
  return postAction("/biz/v1/sys/sms", parameter);
}

// 获取图片验证码
export function getRandomImage() {
  return getAction("/code/image");
}

// 退出接口
export function logout() {
  return postAction("/auth/logout");
}

// 获取当前租户信息
export function getTenantInfo() {
  return getAction("/bs/v1/tenants", {});
}

// 获取当前租户信息
export function menuInit() {
  return getAction("/lc/v1/m/menu/init");
}

//获取用户信息
export function getUserInfo() {
  return getAction("/bs/users/getInfo");
}

export function getCornerAll() {
  return getAction(`lc/v1/m/menu/getCornerAll`)
}

//  修改密码
export function updatePassword(parameter: any) {
  return postAction('/auth/account/changeUserAccountPwd', makeFormData(parameter))
}

// 重置密码
export function resetPassword(data: any) {
  return postAction('/auth/pwd/reset', data);
}

