import { defineStore } from "pinia";

export const useApproveFlowList = defineStore(
  'approveList',
  {
    state: () => {
      return {
        approveListState: {
          queryConditon: null as any,
          pageNo: 1,
          scrollTop: 0,
          dataList: [] as any,
          showSkeleton: false,
          refreshing: false,
          finished: false,
          loading: false,
          defaultObjectName: '全部',
          totalListNumber: 0,
          totalsTab: [] as any,
          defaultSelectType: 1,
          objectId: '',
          selectObjectId: '-1',
          isRefresh: false,
          izInvoke: false,
          objectFieldsList: [] as any,
          showConditionList: [] as any, // 缓存普通筛选的条件
          doneSubmitParams: {
            processType: '6',
            conditionDTOList: [],
            pageNo: 1,
            pageSiz: 10,
            searchValue: '',
            source: 2
          },
          processListParams: {
            processType: '6',
            conditionDTOList: [],
            objectId: "-1",
            pageNo: 1,
            pageSiz: 10,
            searchValue: '',
            showFieldList: [],
            source: 2
          }
        }
      }
    },
    actions: {
      clearApproveListState() {
        this.approveListState.totalListNumber = 0
        this.approveListState.dataList = [] as any
        // approveListState.defaultObjectName = ''
        this.approveListState.objectId = ''
        this.approveListState.selectObjectId = '-1'
        this.approveListState.isRefresh = false;
        this.approveListState.objectFieldsList = []
        // approveListState.showConditionList = [] as any // 缓存普通筛选的条件
        this.approveListState.defaultSelectType = 1
        this.approveListState.izInvoke = false;
        this.approveListState.totalsTab = [] as any
      }
    }
  }
);