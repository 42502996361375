import {defineStore} from 'pinia';

export const dataTransmission = defineStore('dataTransmission', {
    state: () => {
        return {
            dataType: {
                dataArray: [] as any,
                dataObject: {} as any,
                detailsTab: [] as any,//详情页tab标记记录 key:record, value:index
                objectId: '',
                recordId: '',
                isHideRequired: false, //是否隐藏非必填字段
            } as any,
            locationValue: '', //
            locationInfo: {},
            hideEmptyChangeFlag: false,
            hideEmpty: false,
            hideEmptyKey: '',
            hideEmptyValue: {} as any,

            keyboardHeight: 0,
            fieldPoint: 0,
            fieldPointChange: false,

            keyboardData: {
                height: 0,
            },
        };
    },
    actions: {
        clearData() {
            this.dataType.dataArray = [];
            this.dataType.dataObject = {};
        },
        pushTabHideEmpty(pageKey: string, tabKey: string, value: boolean) {
            if (this.hideEmptyValue) {
                if (!this.hideEmptyValue[pageKey]) {
                    this.hideEmptyValue[pageKey] = {};
                }
                if (!this.hideEmptyValue[pageKey][tabKey]) {
                    this.hideEmptyValue[pageKey][tabKey] = {};
                }
            }
            this.hideEmptyValue[pageKey][tabKey] = value;
        },
        getTabHideEmpty(pageKey: string, tabKey: string) {
            if (this.hideEmptyValue && this.hideEmptyValue[pageKey] && this.hideEmptyValue[pageKey][tabKey]) {
                return this.hideEmptyValue[pageKey][tabKey];
            } else {
                return false;
            }
        },
        delTabHideEmpty(pageKey: string, tabKey: string) {
            if (pageKey) {
                if (tabKey) {
                    delete this.hideEmptyValue[pageKey][tabKey];
                } else {
                    delete this.hideEmptyValue[pageKey];
                }
            }
        },
    },
    persist: true
});