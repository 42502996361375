import { defineStore } from 'pinia';

export const locationStore = defineStore('locationInfo', {
  state: () => {
    return {
      locationTime: '',
      locationId:  '' as any,
      locationData:  '' as any,
    };
  },
  actions: {
    clearLocation() {
      this.locationId =''
      this.locationData =''
    },
  },
  persist: true
});