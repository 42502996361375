import { defineStore } from 'pinia';

export const useLoginStore = defineStore('loginStore', {
  state: () => {
    return {
      tabList: [
        { id: 1, name: '验证码登录' },
        { id: 2, name: '账号登录' }
      ],
      tab1value: 0,
      getCodeBtn: false,
      loginType: 'sms_code',
      menuInitMask: true,
      privacyShowSta: false,
      privacyCheckSta: false,
      formData: {
        pwd: '',
        email: '',
        mobile: '',
        inputCode: ''
      },
    };
  },
  actions: {
     clearLoginState() {
      this.tab1value = 0;
      this.getCodeBtn = false;
      this.loginType = 'sms_code';
      this.menuInitMask = true;
      this.privacyShowSta = false;
      this.privacyCheckSta = false;
      this.formData = {
        pwd: '',
        email: '',
        mobile: '',
        inputCode: ''
      };
    }
  },
});