import { defineStore } from 'pinia';
import { isEmpty } from "@/utils/utils";
import { userStore } from './user';
import { getApplicationConfigById } from '@/api/application';

export const applicationStore = defineStore('applicationInfo', {
  state: () => {
    return {
      scrollTop: 0,
      refreshing: false,
      reports: [] as any,
      applicationCategories: [] as any,
      hasApp: true,
      tabVOList: [] as any,
      currentTabVOList: [] as any,
      level1TabsIndex: 0,
      level2TabsIndex: 0,
      loadComplete: false,
      top: 0,
      firstCall: false,
    };
  },
  actions: {
    async request(callBack?: Function) {
      this.firstCall = true;
      const applicationCenter: Object = { id: 1, name: "应用中心" }
      const userInfo = userStore();
      const tabBarApplication: any = userInfo.tabBarApplication;
      let res: any = await getApplicationConfigById({
        bottomNavigationId: tabBarApplication?.id ?? ''
      })
      if (res?.success && !isEmpty(res?.data)) {
        this.hasApp = res?.data?.hasApp
        this.applicationCategories = res?.data?.apps ?? []
        this.tabVOList = res?.data?.tabVOList ?? []
        let reports: Array<Object> = res?.data?.reports ?? []
        if (reports.length > 0 ||
          this.applicationCategories?.length > 0 ||
          this.tabVOList?.length > 0) {
          this.reports = [applicationCenter, ...reports]
        }

        if (this?.applicationCategories?.length > 0) {
          const _index = this.level2TabsIndex < this.applicationCategories?.length ? this.level2TabsIndex : 0
          this.currentTabVOList = this.applicationCategories[_index]?.tabVOList
        }
      }
      this.loadComplete = true;
      this.refreshing = false;
      callBack && callBack();
    },
    clearApplicationInfo() {
      this.scrollTop = 0;
      this.reports = [] as any;
      this.applicationCategories = [] as any;
      this.hasApp = true;
      this.tabVOList = [] as any;
      this.currentTabVOList = [] as any;
      this.level1TabsIndex = 0;
      this.level2TabsIndex = 0;
      this.loadComplete = false;
      this.top = 0;
      this.firstCall = false;
    },
  }
});