/**
 * 获得平台
 */
export const getPlatForm = () => {
    return localStorage.getItem('platform');
}

/**
 * 用户登录成功，发送消息给原生
 */
export const sendToNativeUserLogin = (data: any) => {
    const platform = getPlatForm();
    if (platform?.includes('android')) {
        if (window.android != null && typeof (window.android) != "undefined") {
            window.android.userLogin(data);
        }
    } else if (platform?.includes('iOS')) {
        window.setupWebViewJavascriptBridge((bridge: any) => {
            bridge.callHandler('userLogin', data, () => { });
        })
    }
}

/**
 * 已登录的用户，发送用户信息给原生
 */
export const sendUserInfoToNative = (data: any) => {
    const platform = getPlatForm();
    if (platform?.includes('android')) {
        if (window.android != null && typeof (window.android) != "undefined") {
            window.android.setLoginUserInfo(data);
        }
    } else if (platform?.includes('iOS')) {
        window.setupWebViewJavascriptBridge((bridge: any) => {
            bridge.callHandler('setLoginUserInfo', data, () => { });
        })
    }
}

/**
 * 用户退出登录，发送消息给原生
 */
export const sendToNativeUserLogout = () => {
    const platform = getPlatForm();
    if (platform?.includes('android')) {
        if (window.android != null && typeof (window.android) != "undefined") {
            window.android.userLogout();
        }
    } else if (platform?.includes('iOS')) {
        window.setupWebViewJavascriptBridge((bridge: any) => {
            bridge.callHandler('userLogout', null, () => { });
        })
    }
}

/**
 * 获得version信息
 * @returns {  "appVersionCode": "90","appVersionName": "3.15.0","deviceType": "Redmi 21091116C","h5VersionCode": "75","h5VersionName": "1.0.0.75","osVersion": "13"} 
 */
export const getVersionInfo = (callback: Function) => {
    const platform = getPlatForm();
    if (platform === 'android') {
        if (window.android != null && typeof (window.android) != "undefined") {
            const version = window.android.getVersion();
            callback && callback(JSON.parse(version))
        }
    } else if (platform?.includes('iOS')) {
        window.setupWebViewJavascriptBridge((bridge: any) => {
            bridge.callHandler('getVersion', null, (data: any) => {
                let info = JSON.parse(data) ?? {}
                callback && callback(info)
            });
        })
    } else {
        callback && callback({})
    }
}

/**
 * 在原生端输出日志，方便调试
 */
export const printLogInNativeForTest = (data: any) => {
    const platform = getPlatForm();
    if (platform?.includes('android')) {
        if (window.android != null && typeof (window.android) != "undefined") {
            window.android.printLogInNativeForTest(data);
        }
    } else if (platform?.includes('iOS')) {
        //do nothing
    }
}

/**
 * 跳转到原生导航
 * { location: this.state?.value, latitude: this.center.lat, longitude: this.center.lng }
 */
export const openMapNavigation = (data: any) => {
    const platform = getPlatForm();
    if (platform?.includes('android')) {
        if (window.android != null && typeof (window.android) != "undefined") {
            window.android.openMapNavigation(data);
        }
    } else if (platform?.includes('iOS')) {
        window.setupWebViewJavascriptBridge((bridge: any) => {
            bridge.callHandler('openMapNavigation', data, () => { });
        })
    }
}

/**
 * 请求原生定位，定位成功后回调h5网页的nativeLocationSuccess方法
 * callBackId字符串: "2193892839289"
 */
export const requestLocationOnce = (callBackId: any) => {
    const platform = getPlatForm();
    if (platform?.includes('android')) {
        if (window.android != null && typeof (window.android) != "undefined") {
            window.android.requestLocationOnce(callBackId);
        }
    } else if (platform?.includes('iOS')) {
        window.setupWebViewJavascriptBridge((bridge: any) => {
            bridge.callHandler('requestLocationOnce', callBackId, (data: any) => {
                const info = JSON.parse(data) ?? {}
                window.nativeLocationSuccess(callBackId, info)
            });
        })
    }
}

/**
 * 预览文件
 * { 'fileName': fileName,'fileType': fileType, 'fileNetUrl': fileNetUrl}
 */
export const openFilePreview = (data: any) => {
    const platform = getPlatForm();
    if (platform?.includes('android')) {
        if (window.android != null && typeof (window.android) != "undefined") {
            window.android.openFilePreview(data);
        }
    } else if (platform?.includes('iOS')) {
        window.setupWebViewJavascriptBridge((bridge: any) => {
            bridge.callHandler('openFilePreview', data, () => { });
        })
    }
}

/**
 * 开始轨迹打点
 * 
 */
export const startMapTracker = () => {
    const platform = getPlatForm();
    if (platform?.includes('android')) {
        if (window.android != null && typeof (window.android) != "undefined") {
            window.android.startMapTracker();
        }
    } else if (platform?.includes('iOS')) {
        window.setupWebViewJavascriptBridge((bridge: any) => {
            bridge.callHandler('startMapTracker', null, () => { });
        })
    }
}

/**
 * 停止轨迹打点
 * 
 */
export const stopMapTracker = () => {
    const platform = getPlatForm();
    if (platform?.includes('android')) {
        if (window.android != null && typeof (window.android) != "undefined") {
            window.android.stopMapTracker();
        }
    } else if (platform?.includes('iOS')) {
        window.setupWebViewJavascriptBridge((bridge: any) => {
            bridge.callHandler('stopMapTracker', null, () => { });
        })
    }
}

/**
 * 获得底部状态栏高度
 */
export const getNavigationBarHeight = (callback: Function) => {
    const platform = getPlatForm();
    if (platform?.includes('android')) {
        if (window.android != null && typeof (window.android) != "undefined") {
            if (window.android?.getNavigationBarHeight) {
                callback && callback(window.android?.getNavigationBarHeight() / window.devicePixelRatio)
            } else {
                callback && callback(0);
            }
        } else {
            callback && callback(0);
        }
    } else if (platform?.includes('iOS')) {
        window.setupWebViewJavascriptBridge((bridge: any) => {
            bridge.callHandler('getSafeArea', null, (data: any) => {
                let info = JSON.parse(data) ?? {}
                callback && callback(info?.bottom ?? 0)
            });
        })
    } else {
        callback && callback(0)
    }
}

/**
 * 获得底部状态栏高度
 */
export const getStatusBarHeight = (callback: Function) => {
    const platform = getPlatForm();
    if (platform?.includes('android')) {
        if (window.android != null && typeof (window.android) != "undefined") {
            if (window.android?.getStatusBarHeight) {
                callback && callback(window.android?.getStatusBarHeight() / window.devicePixelRatio)
            } else {
                callback && callback(0);
            }
        } else {
            callback && callback(0);
        }
    } else if (platform?.includes('iOS')) {
        window.setupWebViewJavascriptBridge((bridge: any) => {
            bridge.callHandler('getSafeArea', null, (data: any) => {
                let info = JSON.parse(data) ?? {}
                callback && callback(info?.top ?? 0)
            });
        })
    } else {
        callback && callback(0)
    }
}

///地址編碼為經緯度
export const requestTransAddressToLngLat = (address: string, callback: Function) => {
    const platform = getPlatForm();
    if (platform === 'android') {
        if (window.android != null && typeof (window.android) != "undefined") {
            const info = window.android.requestTransAddressToLngLat(address);
            callback && callback(JSON.parse(info))
        }
    } else if (platform?.includes('iOS')) {
        window.setupWebViewJavascriptBridge((bridge: any) => {
            bridge.callHandler('requestTransAddressToLngLat', address, (data: any) => {
                let info = JSON.parse(data) ?? {}
                callback && callback(info)
            });
        })
    } else {
        callback && callback({})
    }
}

export const statusBarDarkFont = (isDark: boolean) => {
    const platform = getPlatForm();
    if (platform?.includes('android')) {
        if (window.android != null && typeof (window.android) != "undefined") {
            if (window.android?.statusBarDarkFont) {
                window.android?.statusBarDarkFont(isDark);
            }
        }
    }
}

/**
 *  返回底部状态栏高度对象
 */
// const getBottom = () => {
//     return `{'padding-bottom':${getNavigationBarHeight()}px}"`
// }