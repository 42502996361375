import axios from "axios";
import { userStore } from "../../store/user";
import { printLogInNativeForTest,getPlatForm,getVersionInfo } from "@/utils/nativeUtils.ts";
import { versionStore } from "@/store/versionInfo"

const apiBaseUrl = import.meta.env.VITE_UNIAPP_API_BASE_URL;


const CONSOLE_COLOR = "background: #4368aa;color: #FFFFFF";
const CONSOLE_COLOR_ERROR = "background: #e83838;color: #FFFFFF";
const ENABLE_DEBUG = undefined;

const localUrl = 'https://gateway-beta.xiangshucloud.com/'
export const baseUrl = apiBaseUrl !== '/' ? apiBaseUrl : localUrl;

// 创建axios实例
const service = axios.create({
  // baseURL: "https://gateway.xiangshucloud.com/" || "/", // api的base_url
  baseURL: baseUrl,
  timeout: 1 * 60 * 1000,
});


service.defaults.withCredentials = true;

service.interceptors.response.use(
  (response:any) => {
    printResponse(response);
    if (response.config.method === "options") return; // 如果是预检请求直接返回

    let data = response.data;
    // 文件流的时候返回
    if (response.config.responseType === "blob") {
      if (data.type == "application/json") {
        // 报错不是流的时候返回
        try {
          data = JSON.parse(data);
        } catch (e) {
          console.error(e);
        }
        return data;
      }
      return response;
    }
    if (response.request.responseXML) {
      // blobToJson(data);
    } else if (typeof data === "string") {
      // 服务器挂了
      !response.config?.hideMessage && handleNetworkError({ message: data });
    } else if (!data.success && ![30102, 30107, 30103, 30206, 302018, 30207, 30204, 302012, 302010, 30209, 30208, 302011, 30206, 30207, 302015, 110001].includes(data.code)) {
      !response.config?.hideMessage && handleNetworkError(data);
    }
    return data;
  },
  (error) => {
    if (error.response) {
      let data = error.response.data;
      console.log("------异常响应------", error.response.status);
    }
    return { ...error, success: false };
  }
);

service.interceptors.request.use((config:any) => {
  const userInfo = userStore();
  const token = userInfo.token
  if(token){
    config.headers['t_id'] = token || ''
  }
  config.headers['os-type'] = 'H5'
  if(getPlatForm()){
    config.headers['os-type'] = getPlatForm()
  }
  const { versionInfo } = versionStore();
  if (versionInfo?.osVersion) {
    config.headers['os-version'] = versionInfo?.osVersion
  } 
  if (versionInfo?.appVersionName) {
    config.headers['app-version-name'] = versionInfo?.appVersionName
  } 
  if (versionInfo?.appVersionCode) {
    config.headers['app-version-code'] = versionInfo?.appVersionCode
  } 
  if (versionInfo?.h5VersionName) {
    config.headers['h5-version-name'] = versionInfo?.h5VersionName
  } 
  if (versionInfo?.h5VersionCode) {
    config.headers['h5-version-code'] = versionInfo?.h5VersionCode
  } 
  if (versionInfo?.deviceType) {
    config.headers['device-type'] = versionInfo?.deviceType
  } 
  const tenantId = userInfo?.userInfos?.tenantInfo?.id ?? ''
  const userId = userInfo?.userInfos?.userInfo?.userId ?? ''
  config.headers['tenantId'] = tenantId
  config.headers['userId'] = userId
  if (config.method === "get") {
    config.params = {
      ...config.params,
      _t: new Date().getTime(),
    };
  }
  // 发送请求头信息去原生里面看看
  // printLogInNativeForTest(JSON.stringify(config.headers))
  return config;
});

function printResponse(response: any) {
  if (ENABLE_DEBUG) {
    let hasError = response.data.error;
    if (hasError) {
      console.log("%c Http ", CONSOLE_COLOR_ERROR, "<- URL: " + response.config.url + "\n       <- error:", response.data.error);
    } else {
      console.log("%c Http ", CONSOLE_COLOR, "<- URL: " + response.config.url + "\n       <- data:", response.data);
    }
  }
}

function handleNetworkError(error :any) {
  
}

export default service;
