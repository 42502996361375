import { defineStore } from "pinia";
import localforage from 'localforage'
import dayjs from "dayjs";
import { randomNumber } from '@/utils/utils';
import cloneDeep from "lodash/cloneDeep";



let offlineState = {
  status: false,
  offlineData: [] as any,
  pageType: '' as string,
  userByPinyin: {},
  userTreeList: [],
  postTreeList: [],
  currentObject: {},
  parentObject: {},
  currentDetailView: {},
  temporaryData: [] as any,
  izLocalDetail: false,
  businessView: '',
  offlinePacketName: '',
  currentObjectID: '',
  izPendingPage: false,
  tabCurrent: 0
}

export const useOffLine = () => {
  return defineStore('useOffLine', {
    state: () => ({
      offlineState:{
        status: false,
        offlinePacketName: '',
        izLocalDetail: false,
        izPendingPage: false,
        pageType: '',
        izInit: false,
        chunksLen:1,
        tabCurrent: 1,
        temporaryData:[] as any,
        userByPinyin: {} as any,
        userTreeList: [] as any,
        postTreeList: [] as any,
        choosedPackageId: '',
        businessView:'',
        currentObjectID:'',
        parentObjectID:'',
        izBtnUpdate:false,
        currentRecordId: '',
        currentViewId: '',
      }
    }),
    actions: {
      setOfflineStauts(status:boolean){
        this.offlineState.status = status;
      },
      setPacketName(name:string){
        this.offlineState.offlinePacketName = name;
      },
      setPageType(type:string){
        this.offlineState.pageType = type;
      },
      setChunksLen(chunksLen:number){
        this.offlineState.chunksLen = chunksLen;
      },
      setUserByPinyin(data:any){
        this.offlineState.userByPinyin = data;
      },
      setUserTreeList(data:any){
        this.offlineState.userTreeList = data;
      },
      setPostTreeList(data:any){
        this.offlineState.postTreeList = data;
      },
      setChooseId(id:string){
        this.offlineState.choosedPackageId = id;
      },
      setCurrentObjectId(id:string){
        this.offlineState.currentObjectID = id;
      },
      setParentObjectId(id:string){
        this.offlineState.parentObjectID = id;
      },
      setTemporaryData(val:any){
        this.offlineState.temporaryData = val;
      },
      setBusinessView(id:string){
        this.offlineState.businessView = id;
      },
      setIzBtnUpdate(bul:boolean){
        this.offlineState.izBtnUpdate = bul;
      },
      setCurrentRecordId(id:string){
        this.offlineState.currentRecordId = id;
      },
      setCurrentViewId(id:string){
        this.offlineState.currentViewId = id;
      }
    },
    persist: true
  })
 
}

const getConfig = (_data,params) =>{
  const _params = params;
  // let __data:any = [];
  // const offlineModule = uni.requireNativePlugin("OfflineModule");
  // let __dataStr = '';
  // offlineModule.pullOfflineData((total: number) => {
  //     for (let i = 0; i < total; i++) {
  //         const offLineData: { slice: number, data: string, last: boolean } = offlineModule.getOffLineData(i);
  //         __dataStr += offLineData.data;
  //     }
  // })
  // __data = JSON.parse(__dataStr);
  const {offlineState,setCurrentObjectId,setParentObjectId} = useOffLine()();
  const currentObject = _data.find(el => el.object.id == _params.object_id);
  // const { setOfflineDB } = useOfflineData()();
  // setOfflineDB('currentObjectID',currentObject?.object.id)
  // setOfflineDB('currentObject',currentObject)
  // setOfflineDB('parentObject',currentObject)
  setCurrentObjectId(currentObject.object.id)
  setParentObjectId(currentObject.object.id)
  // offlineState.currentObjectID = currentObject.object.id;

  // offlineState.currentObject = currentObject;
  // offlineState.parentObject = currentObject;


  let viewId;
  const {pageType} = useOffLine()().offlineState;
  if(pageType == '1'){
      viewId = currentObject.createViewMap[_params.businessType_id];     
  }else if(pageType == '2'){
      viewId = currentObject.editViewMap[currentObject.recordBusinessTypeMap[_params.record_id]];
  }else{
      viewId = currentObject.detailViewMap[currentObject.recordBusinessTypeMap[_params.record_id]];
  }
  return {currentObject, viewId} 
}

const queryDetailPageConfig = (_data,params) =>{
  const data:any = {}
  let viewId = '';
  const { currentObject } = getConfig(_data.offlineData,params);
  const {izLocalDetail,temporaryData,businessView,pageType,izBtnUpdate} = useOffLine()().offlineState;
  const { setCurrentRecordId } = useOffLine()();
  if(izLocalDetail && pageType !='1'){
      if(pageType == '2'){
          viewId =  currentObject.editViewMap[businessView];
      }else{
          viewId =  currentObject.detailViewMap[businessView];
      }
      // const record = JSON.parse(localStorage.getItem('temporaryData')).find(el => el.offline.key == params.data.record_id);
      let record = cloneDeep(temporaryData.find(el => el.offline.key == params.record_id));
      if(izBtnUpdate){
        const idFieldKey = currentObject.object.idFieldKey;
        record.value = Object.assign(currentObject.pageReturn?.records.find(el => el[idFieldKey] == params.record_id), temporaryData.find(el => el.offline.key == params.record_id).value);
      }
      record.value['4b5a73b9a90209b09196ce99'] = true;
      record.value['9a53e731a3f0b32feb0d70af'] = true;
      data.recordMap = record.value;
  }else{
      const config  = getConfig(_data.offlineData,params);
      viewId = config.viewId;
      const idFieldKey = currentObject.object.idFieldKey;
      data.recordMap = currentObject.pageReturn?.records.find(el => el[idFieldKey] == params.record_id)
      // if(!data.recordMap){
      //     uni.showToast({ title: '无法查询到该条信息，请确定离线数据的范围。', icon: 'error', mask: true });
      //     return{
      //         data:{},
      //         success:false
      //     }
      // }
  }
  if(pageType !='1' && !data.recordMap){
      return{
              data:{},
              success:false
          }
  }
  data.object = currentObject.object;
  data.bsRoleObjectDTO = currentObject.bsRoleObjectDTO;
  data.busiRuleList = currentObject.busiRuleMap[viewId] || [];
  data.view = currentObject.viewMap[viewId];
  offlineState.currentDetailView = data.view;
  return {
      data,
      success:true
  }

}

const queryRelation = (_data,params) => {
  let data = [];
  const {currentObject,viewId}  = getConfig(_data.offlineData,params);
  data = currentObject.relationList;
  return {
      data,
      success:true
  }
}
  
const busiruleParse = (_data,params) => {
  let data = {};
  const {izLocalDetail} = useOffLine()().offlineState;
  if(!izLocalDetail){
      data = _data.offlineData.find(el => el.busiRuleParseResponseMap[params.recordId])?.busiRuleParseResponseMap[params.recordId];
  }
  return {
      data,
      success:true
  }
}

const queryUsersByPinyin = (_data,params) => {
  const {userByPinyin} = useOffLine()().offlineState;
  return{
      data: userByPinyin,
      success:true
  }
}

const queryPostTreeList = (_data,params) => {
  const {postTreeList} = useOffLine()().offlineState;
  return{
      data: postTreeList,
      success:true
  }
}

const queryPostUserTreeList = (_data,params) => {
  const {userTreeList} = useOffLine()().offlineState;
  return{
      data: userTreeList,
      success:true
  }
}

const getListConfig = (_data,params) =>{
  const _params = params;
  const currentObject = _data.find(el => el.object.id == _params.object_id);
  return { currentObject };
}

const queryAllById = (_data,params) => {
  const { currentObject } = getListConfig(_data.offlineData,params);
  return{
      data: currentObject?.object,
      success:true
  }
}

const queryObjectFilterByObjectId = (_data,params) =>{
  const { currentObject } = getListConfig(_data.offlineData,params);
  return{
      data: [{id:currentObject.object.id,name: currentObject.viewObjectFilterList ? currentObject.viewObjectFilterList[0].name : ''}],
      success:true
  }
}

const queryObjectPower = (_data,params) =>{
  const { currentObject } = getListConfig(_data.offlineData,params);
  return{
      data: currentObject.bsRoleObjectDTO,
      success:true
  }
}

const queryViewByTypeNew = (_data,params) => {
  const { currentObject } = getListConfig(_data.offlineData,params);
  return{
      data: [currentObject.listView],
      success:true
  }

}

const queryListData = (_data,params) => {
  const currentObject = _data.offlineData.find(el => el.object.id == params.objectFilterId);
  return{
      data: currentObject.pageReturn,
      success:true
  }
}

const queryEnableList = (_data,params) => {
  const currentObject = _data.offlineData.find(el => el.object.id == params.objectId);
  return{
      data: currentObject.businessTypeDtoList,
      success:true
  }
  
}

const queryViewFilter = (_data,params) => {
  const { offlineState } = useOffLine()();
  // console.log(params,'paramsparams',offlineState.currentObjectID)
  const currentObject = _data.offlineData.find(el => el.object.id == offlineState.currentObjectID);
  // const currentView = currentObject.viewMap[currentObject.detailViewMap[currentObject.recordBusinessTypeMap[offlineState.currentRecordId]]];
  const currentView = currentObject.viewMap[params.view_id];
  // const 
  // const data = offlineState.currentObject.viewFilterList.find(el => el.id == params.data.)
  // console.log(offlineState.currentObject,'offlineState.currentObject')
  // queryViewFilterCongif();
  return {
      data:{},
      success: true
  }
}

const queryViewFilterCongif = (_data,params) => {

  const {offlineState} = useOffLine()();
  const currentObject = _data.offlineData.find(el => el.object.id == offlineState.currentObjectID);
  // offlineState.currentObject = offlineState.offlineData.find(el => el.object.id == offlineState.currentObjectID)
  let businessId;
  const {pageType} = useOffLine()().offlineState;

  if(pageType == '3'){
      businessId = currentObject.detailViewMap[currentObject.recordBusinessTypeMap[params.recordId]]

  }else{
      businessId = currentObject.editViewMap[currentObject.recordBusinessTypeMap[params.recordId]]

  }
  const webJson = JSON.parse(currentObject.viewMap[businessId].webJson);
  // webJson
  let filterId,targetObjectId; 
  webJson.componentUiList.forEach(element => {
      if(element.tabs){
          (element.tabs || []).forEach(el => {
              (el.list || []).forEach(item => {
                  if(item.type == 4){
                      if(item.key == params.componentId){
                          filterId = item.options.filterId;
                          targetObjectId= item.options.targetObjectId;
                      }
                  }
              })
          })
      }else{
          if(element.type == 4){
              if(element.key == params.componentId){
                  filterId = element.options.filterId;
                  targetObjectId= element.options.targetObjectId;
              }
          }
      }

  });
  if(!currentObject?.subMataRecordMap){
      console.log('缺少子记录配置')
      return{
          data:{},
          success:false
      }
  }
  if(!_data.offlineData.some(el => el?.object.id == targetObjectId)){
    _data.offlineData.push(currentObject?.subMataRecordMap[targetObjectId]);
  }
  return {
      data:{
          bsRoleObjectDTO: currentObject?.subMataRecordMap[targetObjectId].bsRoleObjectDTO,
          object: currentObject?.subMataRecordMap[targetObjectId].object,
          pageReturn: offlineState.izLocalDetail? {} : currentObject?.offlineSubRecordList[params.recordId][filterId].pageReturn
      },
      success: true
  }

}

const queryDataByViewFilter = (_data,params) => {
  const { offlineState } = useOffLine()();
  const currentObject = _data.offlineData.find(el => el.object.id == offlineState.currentObjectID);
  let businessId;

  const {pageType} = useOffLine()().offlineState;
  if(pageType == '3'){
      businessId = currentObject.detailViewMap[currentObject.recordBusinessTypeMap[params.recordId]]

  }else{
      businessId = currentObject.editViewMap[currentObject.recordBusinessTypeMap[params.recordId]]

  }
  const webJson = JSON.parse(currentObject.viewMap[businessId].webJson);
  // const webJson = JSON.parse(offlineState.currentDetailView.webJson);
  let filterId = '',targetObjectId; 
  webJson.componentUiList.forEach(element => {
      if(element.tabs){
          (element?.tabs || []).forEach(el => {
              (el?.list || []).forEach(item => {
                  if(item.type == 4){
                      if(item.key == params.componentId){
                          filterId = item.options.filterId;
                          targetObjectId= item.options.targetObjectId;
                      }
                  }
              })
          })
      }else{
          if(element.type == 4){
              if(element.key == params.componentId){
                  filterId = element.options.filterId;
                  targetObjectId= element.options.targetObjectId;
              }
          }
      }

  });
  // const childObjId = offlineState.currentObject?.offlineSubRecordList[params.data.recordId][filterId].objectId;
  // const childObject = offlineState.offlineData.find(el => el.object.id == childObjId);
  // console.log(childObject,'childObject')
  const childObject = _data.offlineData.find(el => el.object.id == targetObjectId);
  const childIdKey = childObject.object.idFieldKey;
  const pageReturn = currentObject?.offlineSubRecordList[params.recordId][filterId].pageReturn;
  pageReturn && pageReturn.records && pageReturn?.records.map(el => {
    el['62bad05f08838e7b1a6740c6'] = childObject.recordButtonMap[el[childIdKey]]?.map(item =>{
      return childObject.buttonMap[item]
    })
    return el;
  })
  return {
      data:offlineState.izLocalDetail?offlineState.izPendingPage? currentObject?.offlineSubRecordList[params.recordId][filterId].pageReturn: {} : pageReturn,
      success: true
  }
}

const queryRelevantData = (_data,params) =>{
  const {currentObjectID} = useOffLine()().offlineState;
  const currentObject = _data.offlineData.find(el => el.object.id == currentObjectID);
  return{
      data:currentObject.pageReturn,
      success: true
  }
}

const link = (_data,params) => {
  const { offlineState,setCurrentObjectId,setTemporaryData,temporaryData } = useOffLine()();
  const currentObject = _data.offlineData.find(el => el.object.id == offlineState.currentObjectID);
  const pageReturn = params?.linkIds.map(el => {
      return currentObject.pageReturn.records.find(item => item[currentObject.object.idFieldKey] == el);
  })
  const parentObject = _data.offlineData.find(el => el.object.id == offlineState.parentObjectID);
  const record =  parentObject?.offlineSubRecordList[params?.recordId][params?.viewFilterId].pageReturn.records;
  if(!record){
    parentObject.offlineSubRecordList[params?.recordId][params?.viewFilterId].pageReturn.records = pageReturn;
  }else{
    parentObject?.offlineSubRecordList[params?.recordId][params?.viewFilterId].pageReturn.records.push(...pageReturn);
  }


  const __temporaryData = pageReturn.map(record => {
      const random = randomNumber(12);
      const offline = {
          objectName:currentObject?.object.name,
          updateTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
          status:'1',
          primary:record[currentObject?.object?.primaryFieldId],
          key:random,
      }
      return {
          offline:{
              ...offline
          },
          izLink:true,
          linkIds:[record[currentObject.object.idFieldKey]],
          recordId:params?.recordId,
          viewFilterId:params?.viewFilterId
          
      };
  })
  let allTemporaryData:any;
  if(!temporaryData){
    allTemporaryData = __temporaryData
  }else{
    allTemporaryData = temporaryData.concat(...__temporaryData);
  }
  setTemporaryData(allTemporaryData);
  // offlineState.temporaryData = offlineState.temporaryData.concat(...temporaryData);
  // localStorage.setItem('temporaryData',JSON.stringify(temporaryData))
  // offlineState.currentObject = offlineState.parentObject;
  setCurrentObjectId(offlineState.parentObjectID);

  return {
      success: true,
  }
}

const queryAppPermissionByObjectId = (_data,params) => {
  const currentObject = _data.offlineData.find(el => el.object.id == params.objectId);
  const buttonMap = currentObject.recordButtonMap[params.recordId].map(el => currentObject.buttonMap[el]);
  return {
    data:buttonMap,
    success: true,
}

}
const queryObjectDataById = () =>{
  return {
    success: true,
  }
}

const portMap = {
  'lc/record/queryDetailPageConfig': queryDetailPageConfig,
  'lc/relation/queryByObjecyId': queryRelation,
  'lc/busirule/parse': busiruleParse,
  'bs/users/queryUsersByPinyin': queryUsersByPinyin,
  'bs/post/queryPostTreeList': queryPostTreeList,
  'bs/post/queryPostUserTreeList': queryPostUserTreeList,
  'lc/object/queryAllById/power': queryAllById,
  'lc/list/queryObjectFilterByObjectId': queryObjectFilterByObjectId,
  'lc/sysBtn/queryObjectPower': queryObjectPower,
  'lc/viewType/queryViewByTypeNew': queryViewByTypeNew,
  'lc/list/queryListData': queryListData,
  'lc/businessType/queryEnableList': queryEnableList,
  'lc/relevant/queryViewFilter': queryViewFilter,
  'lc/relevant/queryViewFilterCongif': queryViewFilterCongif,
  'lc/relevant/queryDataByViewFilter': queryDataByViewFilter,
  'lc/relevant/queryRelevantData':queryRelevantData,
  'lc/record/link':link,
  'lc/custBtn/queryAppPermissionByObjectId':queryAppPermissionByObjectId,
  'lc/record/queryObjectDataById': queryObjectDataById,
}

export const useOfflineData = () => {
  return defineStore('useOfflineData', {
    state: () => ({
      offlineDB: localforage.createInstance({
        name: 'offlineDB',
      }),
      offline:{
        offlineData: [] as any,
        pageType: '' as string,
        userByPinyin: {},
        userTreeList: [],
        postTreeList: [],
        currentObject: {},
        parentObject: {},
        currentDetailView: {},
        temporaryData: [] as any,
        izLocalDetail: false,
        businessView: '',
        currentObjectID: '',
        izPendingPage: false,
        tabCurrent: 0,
      }
    }),
    actions: {
      async initDb(){
        this.offlineDB.setItem('offlineState', JSON.stringify(
          {
             offlineData: [] as any,
             pageType: '' as string,
             userByPinyin: {},
             userTreeList: [],
             postTreeList: [],
             currentObject: {},
             parentObject: {},
             currentDetailView: {},
             temporaryData: [] as any,
             izLocalDetail: false,
             businessView: '',
             currentObjectID: '',
             izPendingPage: false,
             tabCurrent: 0,
           }
         ))
         return;
        const offline = await this.initOfflineDB();
        if(!offline){
          const r = await this.offlineDB.getItem('offlineState')
        }
      },
      async setOfflineDB(key: string, value: any) {
        // this.offlineDB.setItem(key, value)
        // return;
        // this.offline = await this.initOfflineDB();
        // if(this.offline){
          this.offline[key] = value;
        // }
        let {setChunksLen} = useOffLine()();
        const chunks = chunkString(JSON.stringify(this.offline),5000);
        setChunksLen(chunks.length)
        for(let i = 0;i<chunks.length;i++){
          this.offlineDB.setItem('offlineState' + i,chunks[i])
          // setOfflineDB('offlineData' + [i],chunks[i]);
      }
        // this.offlineDB.setItem('offlineState', JSON.stringify(this.offline))
      },
      setOffline(value:any){
        this.offline = value;
      },
      
      async initOfflineDB(){
        const {chunksLen} = useOffLine()().offlineState;
        if(chunksLen == 1){
          const result:any = await this.offlineDB.getItem('offlineState')
          return JSON.parse(result);
        }else{
          const arr = Array.from({length:chunksLen}, (v,k) => k)
          let result:any = {}
          const __result = arr.map(async (a,index) =>{
            const offlineChunks =  await this.offlineDB.getItem('offlineState' + index);
            result[index] = offlineChunks;
            // console.log(await this.offlineDB.getItem('offlineData' + index),index)
          })
          return Promise.all(__result).then(() => {
            let resStr = '';
            for(let key in result){
              resStr += result[key]
            }
            return JSON.parse(resStr);
          })
        }

        // console.log(__result,'__result')
        // const result = await this.offlineDB.getItem('offlineData1042')
        // return result;
 
      },
      async offline2Port(url:string,req:any) {
        return await new Promise((resolve,reject) =>{
            resolve();
        }).then(async () => {
            if(portMap[url]){
              // const res = await this.initOfflineDB();
                return portMap[url](this.offline,req);
            }else{
                return {
                    data:'',
                    success:false
                }
            }
        
        })   
    }

    }
    
  })
}

const chunkString = (str, chunkSize) =>{
  const numChunks = Math.ceil(str.length / chunkSize);
  const chunks = new Array(numChunks);
  for (let i = 0, offset = 0; i < numChunks; i++, offset += chunkSize) {
    chunks[i] = str.substr(offset, chunkSize);
  }
  return chunks;
}