/**
 *时间格式化
 * @param date
 * @param format 例如 yyyy-MM-dd
 * @returns {*}
 */

 import dayjs from "dayjs";

 const format = (date, format) => {
    if (!date || !date instanceof Date) {
      return;
    }
    if (typeof date === "string") {
      date = new Date(date);
    }
    const o:any = {
      "M+": date.getMonth() + 1, //month
      "d+": date.getDate(), //day
      "h+": date.getHours(), //hour
      "m+": date.getMinutes(), //minute
      "s+": date.getSeconds(), //second
      "q+": Math.floor((date.getMonth() + 3) / 3), //quarter
      S: date.getMilliseconds(), //millisecond
    };
    if (/(y+)/.test(format)) format = format.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (const k in o) if (new RegExp("(" + k + ")").test(format)) format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
    return format;
  };
  
  /**
   *
   * @param date
   */
  function getYear(date:any) {
    const dateObj = getDateObj(date);
    if (dateObj) {
      return dateObj.getFullYear();
    }
  }
  
  function getPeriod(date:any) {
    const dateObj = getDateObj(date);
    if (dateObj) {
      let period:any = dateObj.getMonth() + 1;
      if (period < 10) {
        period = "0" + period;
      }
      return period;
    }
  }
  
  function getDate(date:any) {
    const dateObj = getDateObj(date);
    if (dateObj) {
      let date:any = dateObj.getDate();
      if (date < 10) {
        date = "0" + date;
      }
      return date;
    }
  }
  
  function getYearAndMontStr(date:any) {
    const year = getYear(date);
    const period = getPeriod(date);
    return `${year}-${period}`;
  }
  
  function getYearAndMontAndDateStr(date:any) {
    const year = getYear(date);
    const period = getPeriod(date);
    const dates = getDate(date);
    return `${year}/${period}/${dates}`;
  }
  
  function createDate(year:any, period:any) {
    return new Date(year, period - 1);
  }
  
  function getDateObj(date:any) {
    let dateObj = null;
    if (date instanceof Date) {
      dateObj = date;
    } else {
      if (typeof date === "string") {
        date = date.replace(new RegExp(/-/gm), "/");
        const dateArr = date.split("/");
        if (dateArr.length === 2) {
          dateObj = new Date(date + "/01");
        } else {
          dateObj = new Date(date);
        }
      } else {
        dateObj = new Date(date);
      }
    }
    return dateObj;
  }
  
  // 返回 年月日时分秒 2014-01-01 12:12:12
  function formatDateTime(date:any, format?:any) {
    if (!format) format = "-";
    const dateObj = getDateObj(date);
    const y:any = dateObj.getFullYear();
    let m:any = dateObj.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    let d:any = dateObj.getDate();
    d = d < 10 ? "0" + d : d;
    let h:any = dateObj.getHours();
    h = h < 10 ? "0" + h : h;
    let minute:any = dateObj.getMinutes();
    minute = minute < 10 ? "0" + minute : minute;
    let second:any = dateObj.getSeconds();
    second = second < 10 ? "0" + second : second;
    return y + format + m + format + d + " " + h + ":" + minute + ":" + second;
  }
  
  //返回一个月的最后一天
  function getMonthLastDate(date:any, format:any) {
    if (!format) format = "-";
    date = date ? new Date(date) : new Date();
    const y = date.getFullYear();
    const m = date.getMonth() + 1;
    let p = m;
    if (p < 10) {
      p = "0" + p;
    }
    if (m === 2) {
      return y % 4 === 0 ? y + format + p + format + 29 : y + format + p + format + 28;
    } else if (m === 1 || m === 3 || m === 5 || m === 7 || m === 8 || m === 10 || m === 12) {
      return y + format + p + format + 31;
    } else {
      return y + format + p + format + 30;
    }
  }
  
  /**
   * 获取上一个月
   * @type 格式'month'/'date'为空默认返回date
   * @date 格式为yyyy-mm-dd的日期，如：2014-01-25
   */
  function getPreMonth(date:any, type?:any, format?:any) {
    if (!format) format = "/";
    date = getYearAndMontAndDateStr(date);
    const arr = date.split("/");
    const year = arr[0]; //获取当前日期的年份
    const month = arr[1]; //获取当前日期的月份
    const day = arr[2]; //获取当前日期的日
    // let days = new Date(year, month, 0);
    // days = days.getDate(); //获取当前日期中月的天数
    let year2 = year;
    let month2:any = parseInt(month) - 1;
    if (month2 === 0) {
      year2 = parseInt(year2) - 1;
      month2 = 12;
    }
    let day2 = day;
    let days2:any = new Date(year2, month2, 0);
    days2 = days2.getDate();
    if (day2 > days2) {
      day2 = days2;
    }
    if (month2 < 10) {
      month2 = "0" + month2;
    }
    console.log(year2,month2)
  
    if (type === "month") {
      return year2 + format + month2;
    } else {
      return year2 + format + month2 + format + day2;
    }
  }
  
  /**
   * 获取下一个月
   * @type 格式'month'/'date'为空默认返回date
   * @date 格式为yyyy-mm-dd的日期，如：2014-01-25
   */
  function getNextMonth(date:any, type?:any, format?:any) {
    if (!format) format = "/";
    date = getYearAndMontAndDateStr(date);
    const arr = date.split("/");
    const year = arr[0]; //获取当前日期的年份
    const month = arr[1]; //获取当前日期的月份
    const day = arr[2]; //获取当前日期的日
    // let days = new Date(year, month, 0);
    // days = days.getDate(); //获取当前日期中的月的天数
    let year2 = year;
    let month2:any = parseInt(month) + 1;
    if (month2 === 13) {
      year2 = parseInt(year2) + 1;
      month2 = 1;
    }
    let day2 = day;
    let days2:any = new Date(year2, month2, 0);
    days2 = days2.getDate();
    if (day2 > days2) {
      day2 = days2;
    }
    if (month2 < 10) {
      month2 = "0" + month2;
    }
  
    if (type === "month") {
      return year2 + format + month2;
    } else {
      return year2 + format + month2 + format + day2;
    }
  }
  
  function getDifferFormatterByDate(date:any) {
    const currentDate = getYearAndMontAndDateStr(new Date()).split("-");
    const [currentYear, currentMonth, currentDay] = currentDate;
    const [targetYear, targetMonth, targetDay] = getYearAndMontAndDateStr(date).split("-");
    const formatter = "YYYY/MM/DD HH:mm:ss";
    if (currentYear !== targetYear) {
      // 年份不一样则显示月-日 时分秒
      return formatter;
    }
    if (currentMonth !== targetMonth) {
      // 年份不一样则显示月-日 时分秒
      return formatter.split("YYYY/")[1];
    }
    if (currentDay !== targetDay) {
      return formatter.split("YYYY/")[1];
    }
    return formatter.split("YYYY/MM/DD")[1];
  }
  function convertSecondsToString(s = 1) {
    const seconds = +s;
    if (seconds < 60) {
      return `${seconds}秒`;
    }
    return formatSeconds(seconds);
  }
  function formatSeconds(value:any) {
    let theTime = parseInt(value); // 需要转换的时间秒
    let theTime1 = 0; // 分
    let theTime2 = 0; // 小时
    let theTime3 = 0; // 天
    if (theTime >= 60) {
      theTime1 = parseInt(theTime / 60);
      theTime = parseInt(theTime % 60);
      if (theTime1 >= 60) {
        theTime2 = parseInt(theTime1 / 60);
        theTime1 = parseInt(theTime1 % 60);
        if (theTime2 >= 24) {
          //大于24小时
          theTime3 = parseInt(theTime2 / 24);
          theTime2 = parseInt(theTime2 % 24);
        }
      }
    }
    let result = "";
    if (theTime > 0) {
      result = "" + parseInt(theTime) + "秒";
    }
    if (theTime1 > 0) {
      result = "" + parseInt(theTime1) + "分钟" + result;
    }
    if (theTime2 > 0) {
      result = "" + parseInt(theTime2) + "小时" + result;
    }
    if (theTime3 > 0) {
      result = "" + parseInt(theTime3) + "天" + result;
    }
    return result;
  }
  export const getWeekFromDateStr = (dateStr:any)=>{
    const d = new Date(dateStr)
    const day = d.getDay()
    const weeks = [
      "周日",
      "周一",
      "周二",
      "周三",
      "周四",
      "周五",
      "周六"
    ]
    return weeks[day]
  }
  
  export const getYearMonthDayWeek = (str:any)=>{
    if(!str){ return  ''}
    const fDay = format(str, 'yyyy-MM-dd');
    const week = getWeekFromDateStr(str);
    const [year,month, day] = fDay.split('-');
    const currentYear = new Date().getFullYear();
    if (currentYear === (+year)){
      return month + "-" + day + " " + week;
    } else {
      return fDay + " " + week;
    }
  }
  export default {
    getDifferFormatterByDate,
    format,
    getYear,
    getPeriod,
    getDate,
    createDate,
    getYearAndMontStr,
    getYearAndMontAndDateStr,
    formatDateTime,
    getMonthLastDate,
    getPreMonth,
    getNextMonth,
    convertSecondsToString,
    getYearMonthDayWeek,
  };
  