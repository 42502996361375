import { getAction, postAction } from "../api/http/index";
import type { IApplicationList,IReportcenterCatchUpReportParams,IReportcenterQueryParams} from '@/typings/interface';

// 登录凭证接口
export function getCredential(parameter?:any) {
  return postAction("/auth/credential", parameter);
}

///自建选项卡跳转-恒石bi
export function authJwtSign() {
  return getAction(`auth/jwt/sign`)
}

///应用
export function getApplicationConfigById(data: IApplicationList) {
  return getAction(`lc/v1/m/menu/getConfigById`, data)
}

///查询获取汇报信息
export function findReportTab() {
    return getAction(`lc/app/findReportTab`)
}


//补交接口
export function reportcenterCatchUpList(data:IReportcenterCatchUpReportParams) {
  return postAction(`lc/reportcenter/v2/catchUpList`, data)
}

//汇报中心查看汇报的查询接口
export function reportcenterList() {
  return getAction(`lc/reportcenter/v2/list`)
}

///查询用户有权限的汇报中心设置
export function queryPowerReportCenter() {
  return getAction(`lc/reportcenter/v2/queryPowerReportCenter`)
}

export function reportAssistant(data:IReportcenterQueryParams) {
  return postAction(`lc/reportcenter/v2/reportAssistant?reportSettingId=${data.reportSettingId}&reportSubmitInfoId=${data.reportSubmitInfoId}`)
}