import { defineStore } from 'pinia';
import { getTenantInfo, logout, menuInit, getCornerAll } from '@/api/user';
import { isEmpty } from "@/utils/utils";
import type { ITabBarItem } from '@/typings/interface';
import { setPagePathWithList } from '@/views/cusTabbar/tabbarConfigure';
import router from '../router';
import { applicationStore } from "@/store/application.ts";
import { showSuccessToast } from 'vant';
import { useAgencyCenter } from './agency-center';
import { useApproveFlowList } from './approveFlowList';
import { useBusinessFlowTodoList } from './businessFlowTodoList';
import { useStageFlowList } from './stageFlowList';
import { sendToNativeUserLogout } from "@/utils/nativeUtils.ts";
import { sendToNativeUserLogin } from "@/utils/nativeUtils.ts";
import { appBridge } from '@/store/appBridge';
import { useLoginStore } from './login';
import { registerObjectListBaseStore } from './objectList';
import { useTimeList } from './useTimeList';

export const userStore = defineStore('userInfo', {
  state: () => {
    return {
      token: '',
      tenantCode: '',
      isLogin: false,
      userInfos: {
        endUserInfo: {} as any,
        tenantInfo: {} as any,
        userInfo: {} as any
      } as any,
      tabBar: [] as Array<ITabBarItem>,
      tabBarList: [] as Array<ITabBarItem>,
      tabBarMoreList: [] as Array<ITabBarItem>,
      tabBarApplication: [] as Array<ITabBarItem>,
      badgeList: [] as any,
      izInvoke: false,
      izFreshingTabbar: false,
      isFreshingCorner: false,
      extraInfo: null as any,
      platform: '',
      nfcObjectId: '',
      nfcRecordId: '',
      nfcViewId: '',
      currentClickActive: 0,
      routeItem: {} as any,
      changeTask: false,
      changeObjList: false,

      statusBarHeight: 0,
      navigationBarHeight: 0,
    };
  },
  // getters: {
  //   getToken: (state) => {
  //     return state.token
  //   }
  // },
  actions: {
    setToken(token: string) {
      this.token = token;
    },
    loginSync() {
      localStorage.setItem('login_time', String(new Date().getTime()));
      showSuccessToast('登录成功');
    },
    // 更改登录状态
    async switchLoginStateWithRedirect(_tenantCode: string, _token: string, objectId: string, recordId: string) {
      this.token = _token;
      this.tenantCode = _tenantCode;
      this.isLogin = true;
      await this.syncUserInfo();
      await this.requestMenuInit();
      this.loginSync()
      router.push({
        path: `/views/object/detail/index/${objectId}/${recordId}`
      })
      setTimeout(() => {
        const loginInfo = useLoginStore();
        loginInfo.clearLoginState();
      }, 500)
    },
    async switchLoginState(_tenantCode: string, _token: string, endUserInfo?: any) {
      this.token = _token;
      this.tenantCode = _tenantCode;
      this.isLogin = true;
      await this.syncUserInfo();
      await this.requestMenuInit();
      if (endUserInfo && this.userInfos) {
        this.userInfos.endUserInfo =
        {
          endUserId: endUserInfo.endUserId,
          endUserName: endUserInfo.endUserName,
          endUserAvatar: endUserInfo.endUserAvatar,
        }
      }
      const userId = this.userInfos?.userInfo?.userId ?? ''
      this.izFreshingTabbar = true;
      if (userId.length > 0 && this.token.length > 0 && this.tabBar.length > 0) {
        this.loginSync()
        // 用户登录成功，给原生发送一条消息过去
        const __data: any = { 'userId': userId, 'token': this.token, 'tenantId': this.userInfos?.tenantInfo?.id ?? '' }
        const { jPushAlias } = appBridge()
        const _jPushAlias = jPushAlias()
        if (_jPushAlias?.length > 0) {
          __data.jPushAlias = _jPushAlias
        }
        __data.netEnv = import.meta.env.VITE_NODE_ENV
        __data.apiBaseUrl = import.meta.env.VITE_UNIAPP_API_BASE_URL
        __data.h5BaseUrl = import.meta.env.VITE_UNIAPP_H5_BASE_URL
        setTimeout(() => {
          router.replace({
            path: '/views/cusTabbar/index'
          })
          // 发送用户登录成功
          sendToNativeUserLogin(JSON.stringify(__data));
          setTimeout(() => {
            const loginInfo = useLoginStore();
            loginInfo.clearLoginState();
          }, 500)
        }, 500)
      }
    },
    // 获取用户信息
    async syncUserInfo() {
      if (!this.token) return;
      this.userInfos = (await getTenantInfo())?.data;
    },
    // 小红点
    async requestGetCornerAll() {
      const res = await getCornerAll()
      this.badgeList = [...res?.data] || []
      this.isFreshingCorner = false;
    },
    // 获取app底部导航栏配置
    async requestMenuInit(izDefault?: boolean) {
      const res: any = await menuInit()
      if (!isEmpty(res?.data) && res?.success) {
        this.tabBar = res?.data?.bottomNavigationVOS ?? []
        this.tabBarList = [...this.tabBar?.filter(item => {
          // 过滤日历
          return item.type !== 2
        }) ?? []] as any
        for (const key in this.tabBarList) {
          if (Object.prototype.hasOwnProperty.call(this.tabBarList, key)) {
            const element: any = this.tabBarList[key as any]
            if (element.type === 3) {
              this.tabBarApplication = element;
            }
          }
        }
        const maxCount = 5
        if (this.tabBarList?.length > maxCount) {
          const coppyList = JSON.parse(JSON.stringify(this.tabBarList))
          this.tabBarMoreList = coppyList.slice(maxCount - 1, coppyList.length)
          const more: any = { id: 'more', name: '更多', isDefault: false, type: 999 }
          if (this.tabBarMoreList) {
            for (let i = 0; i < this.tabBarMoreList.length; i++) {
              if (this.tabBarMoreList[i].isDefault) {
                more.isDefault = true
                break
              }
            }
          }
          this.tabBarList?.splice(maxCount - 1, 1, more)
          this.tabBarList = this.tabBarList?.slice(0, maxCount)
          setPagePathWithList(this.tabBarMoreList)
        }
        setPagePathWithList(this.tabBarList);
        setPagePathWithList(this.tabBar);
        if(izDefault) {
          this.setDefaultTabbar();
        }
      }
      // return res
    },
    getDefaultItem() {
      if (this.tabBarList?.length) {
        let defaultItem = {} as any;
        this.tabBarList?.map((item: any) => {
          if (item.isDefault) {
            defaultItem = item
          }
        })
        return defaultItem;
      } else {
        console.error('tabBarList为空');
      }
    },
    refreshObjList() {
      const objectListInfo = registerObjectListBaseStore();
      const timeListInfo = useTimeList();
      objectListInfo.clearObjectList();
      timeListInfo.clearTimeList();
      this.changeObjList = false
      setTimeout( () => {
        this.changeObjList = true
      }, 200);
    },
    refreshTask() {
      const objectListInfo = registerObjectListBaseStore();
      const timeListInfo = useTimeList();
      objectListInfo.clearObjectList();
      timeListInfo.clearTimeList();
      // taskTodoListInfo.clearTaskTodoState();
      this.changeTask = false
      setTimeout(() => {
        this.changeTask = true
      }, 200);
    },
    setDefaultTabbar() {
      const objectListInfo = registerObjectListBaseStore();
      // const taskTodoListInfo = useTaskTodoBaseStore();
      const timeListInfo = useTimeList();
      const defaultItem = this.getDefaultItem();
      this.tabBarList?.map((item: any, index: number) => {
        if (defaultItem.id === item.id) {
          this.currentClickActive = index
        }
      })
      if (defaultItem.type == 1 && defaultItem.tabVO.apiName != 'huibao') {
        objectListInfo.clearObjectList();
        // taskTodoListInfo.clearTaskTodoState();
        timeListInfo.clearTimeList();
        if(this.changeObjList) {
          this.changeObjList = false;
          setTimeout(() => {
            this.changeObjList = true
          })
        } else {
          this.changeObjList = true
        }
      }
      this.routeItem = defaultItem
      if (defaultItem.type == 999) { // 更多
        if (this.tabBarMoreList) {
          for (let i = 0; i < this.tabBarMoreList.length; i++) {
            let item = this.tabBarMoreList[i] as any;
            if (item.isDefault) {
              // state.showMore = false
              // refresh(item)
              if (item.type == 1 && item.tabVO.apiName != 'huibao') {
                this.refreshObjList()
              } else if (item.type == 9) {
                this.refreshTask();
              } else {
                this.changeObjList = false
                this.changeTask = false
              }
              this.currentClickActive = 4;
              this.routeItem = item
            }
          }
        }
      }
    },
    logout(islogout: boolean, logoutSuccessCall: any) {
      if (this.token && islogout) {
        this.userLogout((status: boolean) => {
          if (status) {
            this.clearUserInfo()
          }
          logoutSuccessCall(status)
        })
      } else {
        this.clearUserInfo()
        logoutSuccessCall(true)
      }
    },
    async userLogout(logoutStatus: any) {
      const res: any = await logout()
      if (res?.code == 200 || (res?.code == 401)) {
        logoutStatus(true)
      } else {
        logoutStatus(false)
      }
    },
    clearUserInfo() {
      this.currentClickActive = 0;
      this.routeItem = {} as any;
      this.token = '';
      this.tenantCode = '';
      this.isLogin = false;
      this.userInfos = {
        endUserInfo: {} as any,
        tenantInfo: {} as any,
        userInfo: {} as any
      } as any;
      this.tabBar = [] as Array<ITabBarItem>;
      this.tabBarList = [] as Array<ITabBarItem>;
      this.tabBarMoreList = [] as Array<ITabBarItem>;
      this.tabBarApplication = [] as Array<ITabBarItem>;
      const applicationInfo = applicationStore();
      applicationInfo.clearApplicationInfo();
      this.izFreshingTabbar = true;
      this.isFreshingCorner = false;
      // 清除代办中心, 阶段流， 业务流，审批流，任务代办
      const agencyInfo = useAgencyCenter();
      agencyInfo.clearAgencyCenterState();
      const approveListInfo = useApproveFlowList();
      approveListInfo.clearApproveListState();
      const businessListInfo = useBusinessFlowTodoList();
      businessListInfo.clearBusinessListState();
      const stageListInfo = useStageFlowList();
      stageListInfo.clearStageListState();
      // 给原生发送一个退出登录的消息
      sendToNativeUserLogout()
    },
    clearNFCData() {
      this.nfcObjectId = '';
      this.nfcRecordId = '';
      this.nfcViewId = '';
    },
    getExtraInfo() {
      return this.extraInfo;
    },
    setExtraInfo(extraInfo: any) {
      this.extraInfo = extraInfo;
    },
    clearExtraInfo() {
      this.extraInfo = null
    }

  },
  persist: true
});